import React from 'react';

// Style import
import { Container, AlertContainer, AlertStrip } from './styles';

// Interfaces
interface IProps {
  type: 'success' | 'warning' | 'danger' | 'info';
}

const Alert: React.FC<IProps> = ({ children, ...rest }) => {
  return (
    <Container>
      <AlertStrip type={rest.type} />
      <AlertContainer>{children}</AlertContainer>
    </Container>
  );
};

export default Alert;
