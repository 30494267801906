import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { Reducer } from 'redux';
import { createWhitelistFilter } from 'redux-persist-transform-filter';

export default (reducer: Reducer) => {
  const persistedReducer = persistReducer(
    {
      key: `${process.env.REACT_APP_CLIENT_SHORT_NAME}-card`,
      storage,
      transforms: [createWhitelistFilter('auth', ['token'])],
      whitelist: ['auth'],
    },
    reducer,
  );

  return persistedReducer;
};
