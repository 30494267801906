import styled from 'styled-components';
import { setLightness } from 'polished';

// Interfaces
interface IContainerProps {
  src?: string;
  size: number;
  color: string;
}

export const Container = styled.span<IContainerProps>`
  display: inline-block;

  width: ${props => `${props.size / 16}rem`};
  height: ${props => `${props.size / 16}rem`};
  border-radius: 50%;

  border: ${props =>
    props.src ? 'none' : `0.125rem solid ${setLightness(0.5, props.color)}`};
  background: ${props => setLightness(0.85, props.color)};
  position: relative;

  span {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    color: ${props => setLightness(0.5, props.color)};
    font-size: ${props => `${(props.size / 16) * 0.5}rem`};
    font-weight: bold;
    text-align: center;
  }

  img {
    width: ${props => `${props.size / 16}rem`};
    height: ${props => `${props.size / 16}rem`};
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
