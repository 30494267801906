import React from 'react';
import { useTranslation } from 'react-i18next';

// Style import
import { Container, Progress } from './styles';

const Loading: React.FC = () => {
  // Get translation function
  const { t } = useTranslation();

  return (
    <Container>
      <div className="logo">
        <img
          src={process.env.REACT_APP_CLIENT_LOGO_SM_WHITE_URL}
          alt="Cartão Digital"
        />
        <div className="title">
          {t('@loading/DIGITAL_CARD', 'Cartão Digital')}
        </div>
      </div>
      <Progress />
    </Container>
  );
};

export default Loading;
