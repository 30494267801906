import React from 'react';

import Header from '../../modules/Header';
import { Container, Main } from './styles';

const DefaultLayout: React.FC = ({ children }) => (
  <Container>
    <Header />
    <Main>{children}</Main>
  </Container>
);

export default DefaultLayout;
