import React, { useContext } from 'react';
import {
  MdRecentActors,
  MdCreate,
  MdTune,
  MdCheck,
  MdPageview,
} from 'react-icons/md';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';

// Page import
import ModelSelect from './ModelSelect';
import CardData from './CardData';
import CardSettings from './CardSettings';
import CardValidation from './CardValidation';
import CardActivation from './CardActivation';

// Style import
import {
  Container,
  StepsContainer,
  Steps,
  Step,
  ProgressBar,
  Progress,
  Content,
} from './styles';

// Hook import
import useWindowSize from '../../hooks/useWindowSize';
import { CardProvider, useCard } from '../../hooks/useCard';

const Card: React.FC = () => {
  // Theme context
  const themeContext = useContext(ThemeContext);

  // Get translation function
  const { t } = useTranslation();

  // Card context
  const context = useCard();
  const { active_page } = context;

  // Render on window size change
  const [width] = useWindowSize();

  return (
    <Container>
      <StepsContainer>
        <Steps>
          <Step stepNumber={0} activeStep={active_page}>
            <div>
              <MdRecentActors color={themeContext.font_high} size={30} />
            </div>
            <span>
              <p>{t('@card_generator/HEAD_STEP_ONE', 'Etapa 1/5')}</p>
              <p>{t('@card_generator/HEAD_STEP_ONE_LABEL', 'Modelo')}</p>
            </span>
          </Step>
          <Step stepNumber={1} activeStep={active_page}>
            <div>
              <MdCreate color={themeContext.font_high} size={30} />
            </div>
            <span>
              <p>{t('@card_generator/HEAD_STEP_TWO', 'Etapa 2/5')}</p>
              <p>{t('@card_generator/HEAD_STEP_TWO_LABEL', 'Preenchimento')}</p>
            </span>
          </Step>
          <Step stepNumber={2} activeStep={active_page}>
            <div>
              <MdTune color={themeContext.font_high} size={30} />
            </div>
            <span>
              <p>{t('@card_generator/HEAD_STEP_THREE', 'Etapa 3/5')}</p>
              <p>{t('@card_generator/HEAD_STEP_THREE_LABEL', 'Ajustes')}</p>
            </span>
          </Step>
          <Step stepNumber={3} activeStep={active_page}>
            <div>
              <MdPageview color={themeContext.font_high} size={30} />
            </div>
            <span>
              <p>{t('@card_generator/HEAD_STEP_FOUR', 'Etapa 4/5')}</p>
              <p>{t('@card_generator/HEAD_STEP_FOUR_LABEL', 'Confirmação')}</p>
            </span>
          </Step>
          <Step stepNumber={4} activeStep={active_page}>
            <div>
              <MdCheck color={themeContext.font_high} size={30} />
            </div>
            <span>
              <p>{t('@card_generator/HEAD_STEP_FIVE', 'Etapa 5/5')}</p>
              <p>{t('@card_generator/HEAD_STEP_FIVE_LABEL', 'Ativação')}</p>
            </span>
          </Step>

          <ProgressBar>
            <Progress
              size={
                width <= 500
                  ? active_page === 0 || active_page === 4
                    ? 0
                    : 50
                  : active_page * 25
              }
            />
          </ProgressBar>
        </Steps>
      </StepsContainer>
      <Content>
        {active_page === 0 && <ModelSelect />}
        {active_page === 1 && <CardData />}
        {active_page === 2 && <CardSettings />}
        {active_page === 3 && <CardValidation />}
        {active_page === 4 && <CardActivation />}
      </Content>
    </Container>
  );
};

const CardConsumer: React.FC = () => (
  <CardProvider>
    <Card />
  </CardProvider>
);

export default CardConsumer;
