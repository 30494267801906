import React, { useCallback, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useField } from '@unform/core';

// Style import
import { Container } from './styles';

// Interfaces
interface ICustomInputProps {
  name?: string | null;
  value?: any;
  standalone?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  hide?: boolean;
  placeholder?: string | null;
  error?: string | null;

  type?: 'text' | 'password' | 'number';
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  mask?: string;

  helpLabel?: string | null;

  onChange?: (value: any) => any;
  onFocus?: (event: React.FocusEvent) => any;
}

const CustomInput: React.FC<ICustomInputProps> = ({
  name,
  value: originalValue,
  standalone,
  readOnly,
  disabled,
  hide,
  placeholder,
  error,

  type,
  startAdornment,
  endAdornment,
  mask,

  helpLabel,

  onChange,
  onFocus,

  ...rest
}) => {
  // Register field name
  const {
    fieldName,
    defaultValue,
    registerField,
    error: formError,
    clearError,
  } =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    name && !standalone ? useField(name) : ({} as ReturnType<typeof useField>);

  // Local states
  const [value, setValue] = useState<any>(
    originalValue || defaultValue || null,
  );

  // Register field on unform
  useEffect(() => {
    if (name && !standalone)
      registerField({
        name: fieldName,
        getValue: () => value,
        setValue: (ref: any, newValue: any) => setValue(newValue),
        clearValue: () => setValue(null),
      });
  }, [registerField, name, standalone, fieldName, value]);

  // Set local value when external value changes
  useEffect(() => {
    setValue(originalValue);
  }, [originalValue]);

  // Handle input change
  const handleChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      setValue(event.target.value);
      if (typeof onChange === 'function') onChange(event.target.value);
    },
    [onChange],
  );

  // Handle input focus
  const handleFocus = useCallback(
    (event: React.FocusEvent) => {
      if (typeof clearError === 'function') clearError();
      if (typeof onFocus === 'function') onFocus(event);
    },
    [clearError, onFocus],
  );

  return (
    <Container
      hide={hide}
      disabled={disabled}
      readOnly={readOnly}
      error={!!error || !!formError}
      startAdornment={!!startAdornment}
    >
      <div>
        {startAdornment && (
          <span className="startAdornment">{startAdornment}</span>
        )}
        {mask ? (
          <InputMask
            name={name || undefined}
            value={value || ''}
            disabled={disabled || readOnly}
            placeholder={placeholder || undefined}
            type={type}
            onChange={handleChange}
            onFocus={handleFocus}
            mask={mask}
            {...rest}
          />
        ) : (
          <input
            name={name || undefined}
            value={value || ''}
            disabled={disabled || readOnly}
            placeholder={placeholder || undefined}
            type={type}
            onChange={handleChange}
            onFocus={handleFocus}
            {...rest}
          />
        )}
        {endAdornment && <span className="endAdornment">{endAdornment}</span>}
      </div>
      {(error || formError || helpLabel) && (
        <span className="helpLabel">{error || formError || helpLabel}</span>
      )}
    </Container>
  );
};

CustomInput.defaultProps = {
  name: null,
  value: undefined,
  standalone: false,
  readOnly: false,
  disabled: false,
  hide: false,
  placeholder: null,
  error: null,

  type: 'text',
  startAdornment: undefined,
  endAdornment: undefined,
  mask: undefined,

  helpLabel: null,

  onChange: undefined,
  onFocus: undefined,
};

export default CustomInput;
