import { AnyAction } from 'redux';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { REHYDRATE } from 'redux-persist/lib/constants';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';

// API import
import api from '../../../services/api';

// Action import
import {
  sessionRefreshSuccess,
  signFailure,
  signInSuccess,
  signOut,
} from './actions';

// Type import
import { AuthActionTypes } from './types';

// Get translation function
import { t } from '../../../i18n';

export function* signInRequestEffect({ payload }: AnyAction) {
  // Get data from payload
  const { cpf, password } = payload;

  try {
    // Call API
    const response: AxiosResponse = yield call(api.post, 'session', {
      cpf,
      password,
    });

    // Setup token on axios
    api.defaults.headers.Authorization = `Bearer ${response.data.token}`;

    // Handle sign success
    yield put(signInSuccess(response.data));
  } catch (err) {
    // Check if server had return a error
    if (err.response?.data?.message) toast.error(err.response.data.message);
    else
      toast.error(
        t(
          '@general/CONNECTION_FAILURE_ERROR',
          'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
        ),
      );

    // Handle sign failure
    yield put(signFailure());
  }
}

export function signOutEffect() {
  // Clear axios token
  api.defaults.headers.Authorization = undefined;
}

export function* refreshSessionEffect() {
  // Get token from store
  const token: string = yield select(state => state.auth.token);

  try {
    // API call
    const response: AxiosResponse = yield call(api.put, 'session', {
      token,
    });

    // Handle data
    yield put(sessionRefreshSuccess(response.data));
  } catch {
    // Logout
    yield put(signOut());
  }
}

export function* resumeSession({ payload }: AnyAction) {
  if (payload?.auth?.token) {
    try {
      // API call
      const response: AxiosResponse = yield call(api.put, 'session', {
        token: payload.auth.token || null,
      });

      // Setup axios
      api.defaults.headers.Authorization = `Bearer ${response.data.token}`;

      // Handle success
      yield put(signInSuccess(response.data));
    } catch {
      // Logout
      yield put(signOut());
    }
    // Logout
  } else yield put(signOut());
}

export default all([
  takeLatest(REHYDRATE, resumeSession),
  takeLatest(AuthActionTypes.SIGN_IN_REQUEST, signInRequestEffect),
  takeLatest(AuthActionTypes.SIGN_OUT, signOutEffect),
  takeLatest(AuthActionTypes.REFRESH_SESSION, refreshSessionEffect),
]);
