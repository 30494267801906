import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.background_primary};
  gap: 1.875rem;

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    img {
      width: 12.5rem;
    }

    .title {
      font-weight: bold;
      color: ${({ theme }) => theme.font_high};
      font-size: 2rem;
    }
  }

  @media screen and (max-width: 700px) {
    .logo {
      flex-direction: column;
    }
  }
`;

export const Progress = styled(CircularProgress)`
  .MuiCircularProgress-svg {
    color: ${({ theme }) => theme.font_high};
  }
`;
