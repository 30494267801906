import React from 'react';
import { ResponsiveLine, Serie } from '@nivo/line';

// Style import
import { Container } from './styles';

// Interfaces
interface ILineChartProps {
  data: Serie[];
  xLegend?: string;
  xLegendVertical?: boolean;
  yLegend?: string;
  title?: string;
}

const LineChart: React.FC<ILineChartProps> = ({
  data,
  xLegend,
  xLegendVertical,
  yLegend,
  title,
}) => {
  return (
    <Container>
      <div className="title">{title}</div>
      <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 110, bottom: 80, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 0,
          max: 'auto',
          stacked: false,
          reverse: false,
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          legend: xLegend,
          legendOffset: 60,
          legendPosition: 'middle',
          tickRotation: xLegendVertical ? 50 : 0,
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: yLegend,
          legendOffset: -40,
          legendPosition: 'middle',
        }}
        colors={{ datum: 'color' }}
        pointSize={10}
        pointColor="white"
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        enableSlices="x"
        pointLabelYOffset={-12}
        useMesh
        legends={[
          {
            anchor: 'right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </Container>
  );
};

export default LineChart;
