import styled from 'styled-components';
import { rgba } from 'polished';

interface IContainerProps {
  open: boolean;
  size: 'small' | 'normal' | 'large';
}

export const Container = styled.div<IContainerProps>`
  display: ${props => (props.open ? 'flex' : 'none')};
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: ${props => rgba(props.theme.background_low, 0.5)};

  .modal {
    width: 90%;
    max-width: ${props => {
      if (props.size === 'small') return '37.5rem';
      if (props.size === 'normal') return '56.25rem';
      if (props.size === 'large') return '75rem';
      return '56.25rem';
    }};
    max-height: 90vh;
    background: ${({ theme }) => theme.background_secondary};
    border-radius: 0.25rem;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0.9375rem;
      color: ${({ theme }) => theme.font_primary};

      .title {
        display: flex;
        flex-direction: row;
        align-items: baseline;

        h2 {
          font-size: 1.25rem;
        }

        small {
          color: ${({ theme }) => theme.font_primary};
          margin-left: 0.438rem;
        }
      }

      .close {
        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;
        color: ${({ theme }) => theme.font_primary};
        transition: 0.25s;

        &:hover {
          color: ${({ theme }) => theme.font_secondary};
        }
      }
    }

    .body {
      padding: 0.9375rem;
      background: ${({ theme }) => theme.background_high};
      color: ${({ theme }) => theme.font_low};
      border-radius: 0.25rem;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
    }

    .alertContent {
      p {
        margin: 0.3125rem 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 0.625rem;
      }
    }

    .footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      padding: 0.9375rem;
      border-radius: 0.25rem;
      font-weight: bold;
      border: 0;
      transition: 0.5s;
      color: ${({ theme }) => theme.font_low};

      * {
        margin-left: 0.625rem;
      }
    }
  }
`;
