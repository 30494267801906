import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.background_primary};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 1.875rem;
  gap: 1rem;

  img {
    max-width: 9.375rem;
  }

  h1 {
    color: white;
    font-size: 2.5rem;
    text-align: center;
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 1.25rem;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-self: stretch;
  align-items: center;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3.125rem;
    color: #ffffff;

    h1 {
      text-align: right;
      font-style: italic;
    }

    form {
      display: flex;
      flex-direction: column;
      max-width: 31.25rem;
      text-align: center;

      a {
        text-align: right;
        font-style: italic;
        margin: 0.25rem 0;
        color: rgba(255, 255, 255, 0.7);
      }
    }

    .instructions {
      max-width: 16.875rem;
      margin-bottom: 1.25rem;
      text-align: justify;
    }
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;

    .item {
      padding: 0.625rem 1.25rem;

      h1 {
        text-align: center;
      }
    }
  }
`;

export const Footer = styled.div`
  align-self: stretch;
  justify-content: center;
  align-items: center;
  text-align: center;

  img {
    width: 31.25rem;
    max-width: 60%;
  }

  .red-strip {
    background-color: ${({ theme }) => theme.font_danger};
    height: 3.125rem;
    width: 100%;
  }
  .white-strip {
    background-color: ${({ theme }) => theme.font_high};
    height: 3.125rem;
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    border-bottom: 0.125rem solid ${({ theme }) => theme.font_danger};
    font-size: 0.7rem;
  }
`;
