import { combineReducers } from 'redux';

// Reducer import
import auth from './auth/reducer';
import card from './card/reducer';
import statistics from './statistics/reducer';

const rootReducer = combineReducers({
  auth,
  card,
  statistics,
});

export default rootReducer;
