import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { ThemeContext } from 'styled-components';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as Yup from 'yup';
import Cropper from 'react-cropper';
import CropperProps from 'cropperjs';
import {
  MdFolderShared,
  MdImage,
  MdLock,
  MdPinDrop,
  MdDeleteForever,
  MdShare,
  MdInfo,
} from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

// Component import
import { useTranslation } from 'react-i18next';
import Alert from '../../../components/Alert';
import Button from '../../../components/Form/Button';
import Input from '../../../components/Form/Input';
import Select from '../../../components/Form/Select';
import Modal, { IModalRef } from '../../../components/Modal';

// Store import
import { IApplicationState } from '../../../store';
import { IAuthState } from '../../../store/modules/auth/types';
import { refreshSession } from '../../../store/modules/auth/actions';

// Service import
import api from '../../../services/api';

// Context import
import { useCard } from '../../../hooks/useCard';

// Style import
import { Navigation } from '../styles';
import {
  MenuButtons,
  Container,
  ModalFileContent,
  ModalGalleryContent,
  ModalFileFooter,
} from './styles';

// Interfaces
interface ICardData {
  name: string;
  specialty: string;
  phone: string;
  facebook: string;
  linkedin: string;
  twitter: string;
  instagram: string;
  youtube: string;
  website: string;
  address_zipcode: string;
  address_street: string;
  address_number: string;
  address_neighborhood: string;
  address_city: string;
  address_state: string;
}

interface ISystemFiles {
  system_file_id: string;
  public_url: string;
}

const CardData: React.FC = () => {
  // Use contexts
  const cardContext = useCard();
  const themeContext = useContext(ThemeContext);

  // Get translation function
  const { t } = useTranslation();

  // Dispatch hook
  const dispatch = useDispatch();

  // Global states
  const user_data = useSelector<IApplicationState>(
    state => state.auth,
  ) as IAuthState;

  // Constants
  const imageRotations = useMemo(() => [0, 90, 180, 270], []);

  // Local states
  const [loading, setLoading] = useState(false);
  const [memoryFile, setMemoryFile] = useState<string | ArrayBuffer | null>(
    null,
  );
  const [imagePresets, setImagePresets] = useState<ISystemFiles[]>([]);
  const [imageRotation, setImageRotation] = useState(0);

  // Local refs
  const formRef = useRef<FormHandles>(null);
  const modalFileRef = useRef<IModalRef>(null);
  const logoFileRef = useRef<HTMLInputElement>(null);
  const modalImagePresetsRef = useRef<IModalRef>(null);
  const cropperRef = useRef<CropperProps | null>(null);

  // Initial load of user logo presets
  useEffect(() => {
    const loadImagePresets = async () => {
      try {
        // API call
        const response = await api.get('profile/logo/preset');

        // Set the images
        setImagePresets(response.data);
      } catch (err) {
        // Check if server returned a error
        if (err.response?.data?.message) toast.error(err.response.data.message);
        // Display communication error to user
        else
          toast.error(
            t(
              '@general/CONNECTION_FAILURE_ERROR',
              'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
            ),
          );
      }
    };

    // Initial call
    loadImagePresets();
  }, [t]);

  // Submit to use a system file as logo
  const handleUseSystemFile = useCallback(
    async (id: string) => {
      try {
        // API call
        await api.put('profile/logo/preset', { id });

        // Refresh session to display
        dispatch(refreshSession());

        // Message to user
        toast.success(
          t(
            '@card_data_step/IMAGE_SET_SUCCESS',
            'Imagem definida com sucesso.',
          ),
        );

        // Hide the modal
        modalImagePresetsRef.current?.hide();
      } catch (err) {
        // Check if server has returned a error message
        if (err.response?.data?.message) toast.error(err.response.data.message);
        // Send communication error to client
        else
          toast.error(
            t(
              '@general/CONNECTION_FAILURE_ERROR',
              'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
            ),
          );
      }
    },
    [t, dispatch],
  );

  // React to file input change, to start cropping procedure
  const handleFileInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target?.files[0]) {
        // Initializes file reader
        const reader = new FileReader();

        // When file load, set to memory file
        reader.onload = e => {
          // Assign the file to memory
          setMemoryFile(e.target?.result || null);

          // Show the modal
          if (modalFileRef.current) modalFileRef.current.show();
        };

        // Start file reader
        reader.readAsDataURL(event.target.files[0]);
      }
    },
    [],
  );

  // Data submit function
  const handleFileSubmit = useCallback(
    async (blob: Blob) => {
      // Loading set
      setLoading(true);

      try {
        // Initialize the formdata
        const data = new FormData();
        data.append('logo', blob, `logo.jpg`);

        // API call
        await api.put(`profile/logo`, data, {
          headers: {
            'Content-Type': `multipart/form-data`,
          },
        });

        // Refresh the session
        dispatch(refreshSession());

        // Hide the modal
        if (modalFileRef.current) modalFileRef.current.hide();

        // Send message to user
        toast.success(
          t('@card_data_step/IMAGE_SAVE_SUCCESS', 'Imagem salva com sucesso.'),
        );

        // Loading set
        setLoading(false);
      } catch (err) {
        // Check if server has returned a error message and display it
        if (err.response?.data?.message) toast.error(err.response.data.message);
        // Send communication error to user
        else
          toast.error(
            t(
              '@general/CONNECTION_FAILURE_ERROR',
              'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
            ),
          );

        // Loading set
        setLoading(false);
      }
    },
    [t, dispatch],
  );

  // Remove the user logo
  const handleLogoRemove = useCallback(async () => {
    // Loading set
    setLoading(true);

    try {
      // API call
      await api.delete('profile/logo');

      // Loading set
      setLoading(false);

      // Refresh session to update image
      dispatch(refreshSession());

      // Send success message to user
      toast.success(
        t(
          '@card_data_step/IMAGE_REMOVE_SUCCESS',
          'Imagem removida com sucesso.',
        ),
      );
    } catch (err) {
      // Check if server has returned a error message and display it
      if (err.response?.data?.message) toast.error(err.response.data.message);
      // Send communication error to user
      else
        toast.error(
          t(
            '@general/CONNECTION_FAILURE_ERROR',
            'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
          ),
        );

      // Refresh the session
      dispatch(refreshSession());

      // Loading set
      setLoading(false);
    }
  }, [t, dispatch]);

  // Data submit
  const handleSubmit = useCallback(
    async (next: boolean) => {
      // Get data
      let data: ICardData;
      if (formRef.current) data = formRef.current.getData() as ICardData;
      else {
        toast.error(
          t('@card_data_step/FAIL_TO_GET_DATA', 'Falha ao obter os dados.'),
        );
        return;
      }

      // Clear form errors
      if (formRef.current) formRef.current.setErrors({});

      // Loading set
      setLoading(true);

      // Setup a schema do be validated
      const schema = Yup.object()
        .shape({
          name: Yup.string()
            .strict()
            .typeError(
              t(
                '@card_data_step/INVALID_NAME_TYPE',
                'O nome deve ser composto por caracteres alfanuméricos.',
              ),
            )
            .required(
              t(
                '@card_data_step/MISSING_NAME',
                'O nome de exibição é obrigatorio.',
              ),
            ),
          specialty: Yup.string()
            .strict()
            .nullable()
            .typeError(
              t(
                '@card_data_step/INVALID_SPECIALTY_TYPE',
                'A especialidade deve ser composta por caracteres alfanuméricos.',
              ),
            ),
          phone: Yup.string()
            .strict()
            .nullable()
            .typeError(
              t(
                '@card_data_step/INVALID_PHONE_TYPE',
                'O telefone deve ser composto por caracteres alfanuméricos.',
              ),
            ),
          whatsapp: Yup.string()
            .strict()
            .nullable()
            .typeError(
              t(
                '@card_data_step/INVALID_WHATSAPP_TYPE',
                'O WhatsApp deve ser composto por caracteres alfanuméricos.',
              ),
            ),
          facebook: Yup.string()
            .strict()
            .nullable()
            .typeError(
              t(
                '@card_data_step/INVALID_FACEBOOK_TYPE',
                'O Facebook deve ser composto por caracteres alfanuméricos.',
              ),
            )
            .url(
              t(
                '@card_data_step/INVALID_FACEBOOK_URL',
                'URL para o Facebook inválida.',
              ),
            ),
          linkedin: Yup.string()
            .strict()
            .nullable()
            .typeError(
              t(
                '@card_data_step/INVALID_LINKEDIN_TYPE',
                'O LinkedIn deve ser composto por caracteres alfanuméricos.',
              ),
            )
            .url(
              t(
                '@card_data_step/INVALID_LINKEDIN_URL',
                'URL para o LinkeIn inválida.',
              ),
            ),
          twitter: Yup.string()
            .strict()
            .nullable()
            .typeError(
              t(
                '@card_data_step/INVALID_TWITTER_TYPE',
                'O Twitter deve ser composto por caracteres alfanuméricos.',
              ),
            )
            .url(
              t(
                '@card_data_step/INVALID_TWITTER_URL',
                'URL para o Twitter inválida.',
              ),
            ),
          instagram: Yup.string()
            .strict()
            .nullable()
            .typeError(
              t(
                '@card_data_step/INVALID_INSTAGRAM_TYPE',
                'O Instagram deve ser composto por caracteres alfanuméricos.',
              ),
            )
            .url(
              t(
                '@card_data_step/INVALID_INSTAGRAM_URL',
                'URL para o Instagram inválida.',
              ),
            ),
          youtube: Yup.string()
            .strict()
            .nullable()
            .typeError(
              t(
                '@card_data_step/INVALID_YOUTUBE_TYPE',
                'O YouTube deve ser composto por caracteres alfanuméricos.',
              ),
            )
            .url(
              t(
                '@card_data_step/INVALID_YOUTUBE_URL',
                'URL para o YouTube inválida.',
              ),
            ),
          website: Yup.string()
            .strict()
            .nullable()
            .typeError(
              t(
                '@card_data_step/INVALID_WEBSITE_TYPE',
                'O website deve ser composto por caracteres alfanuméricos.',
              ),
            )
            .url(
              t(
                '@card_data_step/INVALID_WEBSITE_URL',
                'URL para o Website inválida.',
              ),
            ),
          address_zipcode: Yup.string()
            .strict()
            .nullable()
            .typeError(
              t(
                '@card_data_step/INVALID_ADDRESS_ZIPCODE_TYPE',
                'O CEP deve ser composto por caracteres alfanuméricos.',
              ),
            ),
          address_street: Yup.string()
            .strict()
            .nullable()
            .typeError(
              t(
                '@card_data_step/INVALID_ADDRESS_STREET_TYPE',
                'O endereço deve ser composto por caracteres alfanuméricos.',
              ),
            ),
          address_number: Yup.string()
            .strict()
            .nullable()
            .typeError(
              t(
                '@card_data_step/INVALID_ADDRESS_NUMBER_TYPE',
                'O número deve ser composto por caracteres alfanuméricos.',
              ),
            ),
          address_neighborhood: Yup.string()
            .strict()
            .nullable()
            .typeError(
              t(
                '@card_data_step/INVALID_ADDRESS_NEIGHBORHOOD_TYPE',
                'O bairro deve ser composto por caracteres alfanuméricos.',
              ),
            ),
          address_city: Yup.string()
            .strict()
            .nullable()
            .typeError(
              t(
                '@card_data_step/INVALID_ADDRESS_CITY_TYPE',
                'A cidade deve ser composta por caracteres alfanuméricos.',
              ),
            ),
          address_state: Yup.string()
            .strict()
            .nullable()
            .typeError(
              t(
                '@card_data_step/INVALID_ADDRESS_STATE_TYPE',
                'O Estado deve ser composto por caracteres alfanuméricos.',
              ),
            ),
        })
        .noUnknown(
          true,
          t('@card_data_step/INVALID_REQUEST', 'Requisição inválida.'),
        );

      try {
        // Validates the schema
        await schema.validate(data, {
          abortEarly: false,
          stripUnknown: false,
        });

        // API call
        await api.put('/profile', data);

        // Refresh session
        dispatch(refreshSession());

        // Required fields
        if (!!data.name && user_data.user?.logo_url) {
          if (next) cardContext.set_active_page(2);
        } else {
          toast.error(
            t(
              '@card_data_step/EXHIBITION_NAME_REQUIRED',
              'Defina um nome de exibição e um logotipo antes de prosseguir.',
            ),
          );
          setLoading(false);
          return;
        }

        // Display success message to user
        toast.success(
          t(
            '@card_data_step/SAVE_DATA_SUCCESS',
            'Dados salvos com sucesso. Prossiga com os ajustes de seu cartão.',
          ),
        );

        // Loading set
        setLoading(false);
      } catch (err) {
        // Check if error was validation
        if (err instanceof Yup.ValidationError) {
          const validationErrors: { [key: string]: string } = {};
          const errors: string[] = [];
          err.inner.forEach(error => {
            errors.push(error.message);
            if (error.path) validationErrors[error.path] = error.message;
          });

          // Display the error to user in each field
          if (formRef.current) formRef.current.setErrors(validationErrors);

          // Display the error to user in a toast message
          toast.error(errors[0]);
        } else if (err.response?.data?.message)
          // Check if server has returned a error message and display it
          toast.error(err.response.data.message);
        // Send communication error to client
        else
          toast.error(
            t(
              '@general/CONNECTION_FAILURE_ERROR',
              'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
            ),
          );
      }

      // Loading set
      setLoading(false);
    },
    [t, cardContext, dispatch, user_data.user?.logo_url],
  );

  // Cropper instance
  const cropper = useMemo(
    () => (
      <Cropper
        onInitialized={instance => {
          cropperRef.current = instance;
        }}
        style={{ width: '100%', maxHeight: '50vh' }}
        src={String(memoryFile)}
        aspectRatio={1 / 1}
        viewMode={0}
        responsive
        rotateTo={imageRotations[imageRotation] || 0}
        background={false}
        dragMode="move"
      />
    ),
    [memoryFile, imageRotation, imageRotations],
  );

  return (
    <>
      <Container>
        <Navigation>
          <nav>
            <Button
              disabled={
                cardContext.selected_card_model === undefined ||
                cardContext.selected_card_color === undefined ||
                loading
              }
              onClick={() => cardContext.set_active_page(0)}
              colorType="tertiary"
              size="small"
            >
              {t('@card_data_step/RETURN', 'Voltar')}
            </Button>
            <p>
              {t(
                '@card_data_step/FORM_DESCRIPTION',
                'Complemente as informações abaixo. Se seus dados estão incompletos ou desatualizados entre em contato com a {{ client }}.',
                {
                  context: process.env.REACT_APP_CLIENT_GENDER,
                  client: process.env.REACT_APP_CLIENT_SHORT_NAME,
                },
              )}
            </p>
            <MenuButtons>
              <Button
                colorType="tertiary"
                size="small"
                disabled={
                  cardContext.selected_card_model === undefined ||
                  cardContext.selected_card_color === undefined ||
                  loading
                }
                onClick={() => handleSubmit(false)}
              >
                {t('@card_data_step/SAVE', 'Salvar')}
              </Button>
              <Button
                colorType="tertiary"
                size="small"
                disabled={
                  cardContext.selected_card_model === undefined ||
                  cardContext.selected_card_color === undefined ||
                  loading
                }
                onClick={() => handleSubmit(true)}
              >
                {t('@card_data_step/NEXT', 'Avançar')}
              </Button>
            </MenuButtons>
          </nav>
        </Navigation>

        <main>
          <Form ref={formRef} onSubmit={() => null}>
            <div className="box">
              <section>
                <div className="box-label">
                  <MdFolderShared size={48} />
                  {t('@card_data_step/BASIC_INFO', 'Informações Básicas')}
                </div>
                <div className="box-field">
                  <div className="grid-1">
                    <Alert type="info">
                      <MdInfo size={36} color="#76bffa" />
                      <div className="alertContent">
                        <p>
                          {t(
                            '@card_data_step/NAME_INSTRUCTIONS',
                            'Nome: Registro no cadastro da {{ client }} (não será utilizado no cartão).',
                            {
                              client: process.env.REACT_APP_CLIENT_SHORT_NAME,
                              context: process.env.REACT_APP_CLIENT_GENDER,
                            },
                          )}
                          <br />
                          {t(
                            '@card_data_step/EXHIBITION_NAME_INSTRUCTIONS',
                            'Nome de Exibição: Poderá ser ajustado, caso necessário.',
                          )}
                          <br />
                          {t(
                            '@card_data_step/DOCUMENT_NUMBER_INSTRUCTIONS',
                            'Número {{ prefix }}: Registro no cadastro da {{ client }} (será utilizado no cartão).',
                            {
                              context: process.env.REACT_APP_CLIENT_GENDER,
                              prefix:
                                process.env.REACT_APP_CLIENT_DOCUMENT_PREFIX,
                              client: process.env.REACT_APP_CLIENT_SHORT_NAME,
                            },
                          )}
                          <br />
                          {t(
                            '@card_data_step/OCCUPATION_INSTRUCTIONS',
                            'Profissão: Registro no cadastro da {{ client }} (será utilizado no cartão).',
                            {
                              client: process.env.REACT_APP_CLIENT_SHORT_NAME,
                              context: process.env.REACT_APP_CLIENT_GENDER,
                            },
                          )}
                          <br />
                          {t(
                            '@card_data_step/SPECIALTY_INSTRUCTIONS',
                            'Especialização: Informe suas especializações (ex: Criminal | Trabalhista | Cível).',
                          )}
                          <br />
                          {t(
                            '@card_data_step/EMAIL_INSTRUCTIONS',
                            'E-mail: Registro no cadastro da {{ client }} (poderá ser utilizado no cartão).',
                            {
                              client: process.env.REACT_APP_CLIENT_SHORT_NAME,
                              context: process.env.REACT_APP_CLIENT_GENDER,
                            },
                          )}
                          <br />
                          {t(
                            '@card_data_step/PHONE_INSTRUCTIONS',
                            'Celular: Informe seu celular (DDD + número).',
                          )}
                          ,
                          <br />
                          {t(
                            '@card_data_step/WHATSAPP_INSTRUCTIONS',
                            'Whatsapp: Informe seu celular (DDD + número).',
                          )}
                          <br />
                        </p>
                      </div>
                    </Alert>
                  </div>
                  <div className="grid-1">
                    <Input
                      standalone
                      label={t(
                        '@card_data_step/NAME',
                        'Nome (Registro no cadastro da {{ client }})',
                        {
                          context: process.env.REACT_APP_CLIENT_GENDER,
                          client: process.env.REACT_APP_CLIENT_SHORT_NAME,
                        },
                      )}
                      variant="outlined"
                      endAdornment={<MdLock size={24} />}
                      disabled
                      size="small"
                      value={user_data.person?.s_nome}
                    />
                  </div>

                  <div className="grid-1">
                    <Input
                      name="name"
                      disabled={loading}
                      label={t(
                        '@card_data_step/EXHIBITION_NAME',
                        'Nome de Exibição',
                      )}
                      variant="outlined"
                      size="small"
                      value={user_data.user?.name || user_data.person?.s_nome}
                    />
                  </div>

                  <div className="grid-3">
                    <Input
                      standalone
                      label={t(
                        '@card_data_step/DOCUMENT_NUMBER',
                        'Número {{ document }}',
                        {
                          document:
                            process.env.REACT_APP_CLIENT_DOCUMENT_PREFIX,
                        },
                      )}
                      variant="outlined"
                      endAdornment={<MdLock size={24} />}
                      disabled
                      size="small"
                      value={user_data.person?.s_codigo}
                    />

                    <Input
                      standalone
                      label={t('@card_data_step/OCCUPATION', 'Profissão')}
                      variant="outlined"
                      endAdornment={<MdLock size={24} />}
                      disabled
                      size="small"
                      value={user_data.person?.occupation?.s_nome}
                    />

                    <Input
                      name="specialty"
                      disabled={loading}
                      label={t('@card_data_step/SPECIALTY', 'Especialização')}
                      variant="outlined"
                      size="small"
                      value={user_data.user?.specialty}
                    />
                  </div>

                  <div className="grid-3">
                    <Input
                      standalone
                      label={t('@card_data_step/EMAIL', 'E-mail')}
                      variant="outlined"
                      endAdornment={<MdLock size={24} />}
                      disabled
                      size="small"
                      value={
                        user_data.person?.emails?.length
                          ? user_data.person.emails.find(
                              email => email.b_principal === 1,
                            )?.s_email
                          : undefined
                      }
                    />

                    <Input
                      name="phone"
                      disabled={loading}
                      label={t('@card_data_step/PHONE', 'Celular')}
                      variant="outlined"
                      size="small"
                      value={user_data.user?.phone}
                      mask="(99) 9 9999-9999"
                      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                        if (formRef.current) {
                          formRef.current.setFieldValue(
                            'whatsapp',
                            e.target.value,
                          );
                        }
                      }}
                    />

                    <Input
                      name="whatsapp"
                      disabled={loading}
                      label={t('@card_data_step/WHATSAPP', 'Whatsapp')}
                      variant="outlined"
                      size="small"
                      value={user_data.user?.whatsapp}
                      mask="(99) 9 9999-9999"
                    />
                  </div>
                </div>
              </section>
            </div>
            <div className="box">
              <section>
                <div className="box-label">
                  <MdShare size={48} />
                  {t('@card_data_step/SOCIAL_TITLE', 'Redes Sociais')}
                </div>
                <div className="box-field">
                  <div className="grid-1">
                    <Alert type="info">
                      <MdInfo size={36} color="#76bffa" />
                      <div className="alertContent">
                        <p>
                          {t(
                            '@card_data_step/SOCIAL_INSTRUCTIONS',
                            'Informe as redes sociais que você utiliza. Para o preenchimento de cada campo você deverá informar o link completo de acesso (ex.: https://www.facebook.com/suaidentificacao).',
                          )}
                        </p>
                      </div>
                    </Alert>
                  </div>
                  <div className="grid-3">
                    <Input
                      name="facebook"
                      disabled={loading}
                      label={t('@card_data_step/SOCIAL_FACEBOOK', 'Facebook')}
                      variant="outlined"
                      size="small"
                      value={user_data.user?.facebook}
                    />

                    <Input
                      name="linkedin"
                      disabled={loading}
                      label={t('@card_data_step/SOCIAL_LINKEDIN', 'Linkedin')}
                      variant="outlined"
                      size="small"
                      value={user_data.user?.linkedin}
                    />

                    <Input
                      name="twitter"
                      disabled={loading}
                      label={t('@card_data_step/SOCIAL_TWITTER', 'Twitter')}
                      variant="outlined"
                      size="small"
                      value={user_data.user?.twitter}
                    />
                  </div>
                  <div className="grid-3">
                    <Input
                      name="instagram"
                      disabled={loading}
                      label={t('@card_data_step/SOCIAL_INSTAGRAM', 'Instagram')}
                      variant="outlined"
                      size="small"
                      value={user_data.user?.instagram}
                    />

                    <Input
                      name="youtube"
                      disabled={loading}
                      label={t('@card_data_step/SOCIAL_YOUTUBE', 'Youtube')}
                      variant="outlined"
                      size="small"
                      value={user_data.user?.youtube}
                    />

                    <Input
                      name="website"
                      disabled={loading}
                      label={t('@card_data_step/SOCIAL_WEBSITE', 'Website')}
                      variant="outlined"
                      size="small"
                      value={user_data.user?.website}
                    />
                  </div>
                </div>
              </section>
            </div>
            <div className="box">
              <section>
                <div className="box-label">
                  <MdPinDrop size={48} />
                  {t('@card_data_step/ADDRESS_TITLE', 'Endereço')}
                </div>
                <div className="box-field">
                  <div className="grid-1">
                    <Alert type="info">
                      <MdInfo size={36} color="#76bffa" />
                      <div className="alertContent">
                        <p>
                          {t(
                            '@card_data_step/FORM_ADDRESS_INSTRUCTIONS',
                            'Informe todos os dados solicitados para garantir o correto funcionamento do link que será gerado no seu Cartão Digital.',
                          )}
                        </p>
                      </div>
                    </Alert>
                  </div>
                  <div className="grid-131">
                    <Input
                      name="address_zipcode"
                      disabled={loading}
                      label={t('@card_data_step/ADDRESS_ZIPCODE', 'CEP')}
                      variant="outlined"
                      size="small"
                      value={user_data.user?.address_zipcode}
                      mask="99999-999"
                    />

                    <Input
                      name="address_street"
                      disabled={loading}
                      label={t('@card_data_step/ADDRESS_STREET', 'Logradouro')}
                      variant="outlined"
                      size="small"
                      value={user_data.user?.address_street}
                    />

                    <Input
                      name="address_number"
                      disabled={loading}
                      label={t('@card_data_step/ADDRESS_NUMBER', 'Número')}
                      variant="outlined"
                      size="small"
                      value={user_data.user?.address_number}
                    />
                  </div>
                  <div className="grid-231">
                    <Input
                      name="address_neighborhood"
                      disabled={loading}
                      label={t(
                        '@card_data_step/ADDRESS_NEIGHBORHOOD',
                        'Bairro',
                      )}
                      variant="outlined"
                      size="small"
                      value={user_data.user?.address_neighborhood}
                    />

                    <Input
                      name="address_city"
                      disabled={loading}
                      label={t('@card_data_step/ADDRESS_CITY', 'Cidade')}
                      variant="outlined"
                      size="small"
                      value={user_data.user?.address_city}
                    />

                    <Select
                      name="address_state"
                      disabled={loading}
                      label={t('@card_data_step/ADDRESS_STATE', 'Estado')}
                      value={user_data.user?.address_state}
                      placeholder={t('@card_data_step/ADDRESS_STATE', 'Estado')}
                      options={[
                        {
                          value: 'RJ',
                          label: t(
                            '@card_data_step/STATE_SELECT_RJ',
                            'Rio de Janeiro',
                          ),
                        },
                        {
                          value: 'AC',
                          label: t('@card_data_step/STATE_SELECT_AC', 'Acre'),
                        },
                        {
                          value: 'AL',
                          label: t(
                            '@card_data_step/STATE_SELECT_AL',
                            'Alagoas',
                          ),
                        },
                        {
                          value: 'AP',
                          label: t('@card_data_step/STATE_SELECT_AP', 'Amapá'),
                        },
                        {
                          value: 'AM',
                          label: t(
                            '@card_data_step/STATE_SELECT_AM',
                            'Amazonas',
                          ),
                        },
                        {
                          value: 'BA',
                          label: t('@card_data_step/STATE_SELECT_BA', 'Bahia'),
                        },
                        {
                          value: 'CE',
                          label: t('@card_data_step/STATE_SELECT_CE', 'Ceará'),
                        },
                        {
                          value: 'DF',
                          label: t(
                            '@card_data_step/STATE_SELECT_DF',
                            'Distrito Federal',
                          ),
                        },
                        {
                          value: 'ES',
                          label: t(
                            '@card_data_step/STATE_SELECT_ES',
                            'Espírito Santo',
                          ),
                        },
                        {
                          value: 'GO',
                          label: t('@card_data_step/STATE_SELECT_GO', 'Goiás'),
                        },
                        {
                          value: 'MA',
                          label: t(
                            '@card_data_step/STATE_SELECT_MA',
                            'Maranhão',
                          ),
                        },
                        {
                          value: 'MT',
                          label: t(
                            '@card_data_step/STATE_SELECT_MT',
                            'Mato Grosso',
                          ),
                        },
                        {
                          value: 'MS',
                          label: t(
                            '@card_data_step/STATE_SELECT_MS',
                            'Mato Grosso do Sul',
                          ),
                        },
                        {
                          value: 'MG',
                          label: t(
                            '@card_data_step/STATE_SELECT_MG',
                            'Minas Gerais',
                          ),
                        },
                        {
                          value: 'PA',
                          label: t('@card_data_step/STATE_SELECT_PA', 'Pará'),
                        },
                        {
                          value: 'PB',
                          label: t(
                            '@card_data_step/STATE_SELECT_PB',
                            'Paraíba',
                          ),
                        },
                        {
                          value: 'PR',
                          label: t('@card_data_step/STATE_SELECT_PR', 'Paraná'),
                        },
                        {
                          value: 'PE',
                          label: t(
                            '@card_data_step/STATE_SELECT_PE',
                            'Pernambuco',
                          ),
                        },
                        {
                          value: 'PI',
                          label: t('@card_data_step/STATE_SELECT_PI', 'Piauí'),
                        },
                        {
                          value: 'RN',
                          label: t(
                            '@card_data_step/STATE_SELECT_RN',
                            'Rio Grande do Norte',
                          ),
                        },
                        {
                          value: 'RS',
                          label: t(
                            '@card_data_step/STATE_SELECT_RS',
                            'Rio Grande do Sul',
                          ),
                        },
                        {
                          value: 'RO',
                          label: t(
                            '@card_data_step/STATE_SELECT_RO',
                            'Rondônia',
                          ),
                        },
                        {
                          value: 'RR',
                          label: t(
                            '@card_data_step/STATE_SELECT_RR',
                            'Roraima',
                          ),
                        },
                        {
                          value: 'SC',
                          label: t(
                            '@card_data_step/STATE_SELECT_SC',
                            'Santa Catarina',
                          ),
                        },
                        {
                          value: 'SP',
                          label: t(
                            '@card_data_step/STATE_SELECT_SP',
                            'São Paulo',
                          ),
                        },
                        {
                          value: 'SE',
                          label: t(
                            '@card_data_step/STATE_SELECT_SE',
                            'Sergipe',
                          ),
                        },
                        {
                          value: 'TO',
                          label: t(
                            '@card_data_step/STATE_SELECT_TO',
                            'Tocantins',
                          ),
                        },
                      ]}
                    />
                  </div>
                </div>
              </section>
            </div>
            <div className="box">
              <section>
                <div className="box-label">
                  <MdImage size={48} />
                  {t('@card_data_step/IMAGE_CROP_TITLE', 'Imagem')}
                </div>
                <div className="box-field">
                  <div className="grid-1 alert">
                    <Alert type="info">
                      <MdInfo size={36} color="#76bffa" />
                      <div className="alertContent">
                        <p>
                          {t(
                            '@card_data_step/IMAGE_CROP_INFO_P1',
                            'Após selecionar uma imagem já carregada no seu dispositivo, você poderá fazer ajustes na imagem.',
                          )}
                          <br />
                          {t(
                            '@card_data_step/IMAGE_CROP_INFO_P2',
                            'Para fazer os ajustes, utilize os controles da máscara (contornos) e também os controles da imagem (redimensionamento e posicionamento).',
                          )}
                          <br />
                          {t(
                            '@card_data_step/IMAGE_CROP_INFO_P3',
                            'O tamanho máximo da imagem final é de 2 megabytes.',
                          )}
                        </p>
                      </div>
                    </Alert>
                  </div>
                  <div className="file">
                    <div className="grid-2">
                      <input
                        ref={logoFileRef}
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={e => handleFileInputChange(e)}
                      />
                      <Button
                        colorType="primary"
                        onClick={() => logoFileRef.current?.click()}
                        loading={loading}
                      >
                        {t('@card_data_step/SELECT_IMAGE', 'Selecionar Imagem')}
                      </Button>
                      <div>
                        <Button
                          colorType="primary"
                          onClick={() => modalImagePresetsRef.current?.show()}
                          loading={loading}
                        >
                          {t(
                            '@card_data_step/SELECT_IMAGE_FROM_GALLERY',
                            'Selecionar Imagem da Galeria',
                          )}
                        </Button>
                      </div>
                    </div>
                    <div className="grid-1">
                      {user_data.user?.logo_url && (
                        <div className="preview">
                          <img src={user_data.user?.logo_url} alt="Imagem" />
                          <Button
                            colorType="secondary"
                            loading={loading}
                            onClick={() => handleLogoRemove()}
                          >
                            <MdDeleteForever
                              size={25}
                              color={themeContext.font_high}
                            />{' '}
                            {t(
                              '@card_data_step/REMOVE_IMAGE',
                              'Remover Imagem',
                            )}
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </Form>
        </main>
      </Container>
      <Modal
        title={t(
          '@card_data_step/MODAL_CROP_TITLE',
          'Ajustes na Imagem Selecionada',
        )}
        size="normal"
        ref={modalFileRef}
        onHide={() => {
          if (logoFileRef.current) logoFileRef.current.value = '';
          if (cropperRef.current) cropperRef.current.destroy();
          setMemoryFile(null);
        }}
        footer={
          <ModalFileFooter>
            <div>
              <Button
                colorType="secondary"
                type="button"
                disabled={loading}
                onClick={() => {
                  if (imageRotation + 1 > 3) setImageRotation(0);
                  else setImageRotation(imageRotation + 1);
                }}
                size="medium"
              >
                {t('@card_data_step/FLIP_IMAGE', 'Girar Imagem')}
              </Button>
            </div>
            <div>
              <Button
                colorType="secondary"
                type="button"
                disabled={loading}
                onClick={() => {
                  if (modalFileRef.current) modalFileRef.current.hide();
                  setMemoryFile(null);
                }}
                size="medium"
              >
                {t('@card_data_step/CANCEL_IMAGE', 'Cancelar')}
              </Button>
              <Button
                colorType="primary"
                type="button"
                disabled={loading}
                size="medium"
                onClick={() => {
                  try {
                    if (cropperRef.current)
                      cropperRef.current.getCroppedCanvas().toBlob(blob => {
                        if (blob) handleFileSubmit(blob);
                        else throw new Error();
                      });
                  } catch (err) {
                    toast.error(
                      t(
                        '@card_data_step/FAIL_TO_CROP_IMAGE',
                        'Falha ao cortar a imagem.',
                      ),
                    );
                  }
                }}
              >
                {t('@card_data_step/SEND_IMAGE', 'Enviar')}
              </Button>
            </div>
          </ModalFileFooter>
        }
      >
        <ModalFileContent>
          <Alert type="info">
            <MdInfo size={36} color="#76bffa" />
            <div className="alertContent">
              <p>
                {t(
                  '@card_data_step/IMAGE_CROP_INSTRUCTIONS',
                  'Se necessário, utilize os controles da máscara (contornos) e também os controles da imagem (redimensionamento e posicionamento',
                )}
              </p>
              <p>
                {t(
                  '@card_data_step/IMAGE_LIMIT',
                  'O limite da imagem cortada é de 2MB.',
                )}
              </p>
            </div>
          </Alert>
          {cropper}
        </ModalFileContent>
      </Modal>
      <Modal
        title={t(
          '@card_data_step/SELECT_FROM_GALLERY',
          'Selecionar Imagem da Galeria',
        )}
        size="normal"
        ref={modalImagePresetsRef}
      >
        <ModalGalleryContent>
          {imagePresets.length ? (
            <div>
              {imagePresets.map(image => (
                <button
                  key={image.system_file_id}
                  type="button"
                  onClick={() => handleUseSystemFile(image.system_file_id)}
                >
                  <img
                    src={image.public_url}
                    alt={t(
                      '@card_data_step/GALLERY_IMAGE',
                      'Imagem da Galeria',
                    )}
                  />
                </button>
              ))}
            </div>
          ) : (
            <span>
              {t(
                '@card_data_step/GALLERY_EMPTY',
                'Nenhuma imagem cadastrada na galeria.',
              )}
            </span>
          )}
        </ModalGalleryContent>
      </Modal>
    </>
  );
};

export default CardData;
