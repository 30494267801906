import React, { useMemo, ButtonHTMLAttributes } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

// Style import
import { Container } from './styles';

// Interfaces
interface IProps {
  size?: string;
  colorType: string;
  disabled?: boolean;
  loading?: boolean;
}

// Types
type ButtonProps = IProps & ButtonHTMLAttributes<HTMLButtonElement>;

// Constants
const types = ['primary', 'secondary', 'tertiary', 'denial'];
const sizes = ['small', 'medium', 'large'];

const Button: React.FC<ButtonProps> = ({
  size,
  colorType,
  disabled,
  children,
  loading,
  ...rest
}) => {
  const checkColorType = useMemo(
    () => (types.includes(colorType) ? colorType : types[0]),
    [colorType],
  );
  const checkSize = useMemo(
    () => (size && sizes.includes(size) ? size : sizes[1]),
    [size],
  );
  const checkDisabled = useMemo(
    () => (disabled || loading ? 'btn-disabled' : ''),
    [disabled, loading],
  );

  return (
    <Container
      type="button"
      className={`${checkColorType} ${checkSize} ${checkDisabled}`}
      disabled={loading || disabled}
      {...rest}
    >
      {loading ? <CircularProgress size={20} /> : children}
    </Container>
  );
};

export default Button;
