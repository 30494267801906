import styled, { css } from 'styled-components';

interface IContainerProps {
  open: boolean;
}

export const Container = styled.div<IContainerProps>`
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;

    width: 100%;

    background-color: ${({ theme }) => theme.background_primary};

    .profile {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 0 1rem;

      img {
        height: 3.75rem;
        width: 3.75rem;
        border-radius: 50%;
      }

      .profileName {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;
        line-height: 1.25rem;
      }

      span.name {
        color: ${({ theme }) => theme.font_high};
        font-size: 0.8125rem;
      }

      strong {
        color: ${({ theme }) => theme.font_warning};
      }
    }

    .top {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      align-self: stretch;

      padding: 0.625rem;
      height: 5.31rem;

      .hamburger {
        display: none;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        top: 1.875rem;
        left: 0.625rem;

        background: none;
        border: 0;
        transition: 0.5s;
        color: ${({ theme }) => theme.font_primary};

        .arrow {
          color: ${({ theme }) => theme.font_primary};
          transition: 0.25s;
        }

        ${({ open }) =>
          open &&
          css`
            .arrow {
              transform: rotate(180deg);
            }
          `}
      }

      .left {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.25rem;

        img {
          max-width: 50vw;
          max-height: 3.75rem;
        }

        .title {
          font-weight: bold;
          color: ${({ theme }) => theme.font_high};
          font-size: 2rem;
        }
      }

      .right {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .profile {
          .profileName {
            @media screen and (max-width: 650px) {
              display: none;
            }
          }
        }
      }
    }

    .navigation {
      width: 100%;
      height: 2.25rem;
      background-color: ${({ theme }) => theme.background_danger};
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1.25rem;
      position: relative;

      nav {
        ul {
          display: flex;
          align-items: center;
          gap: 1.875rem;

          li {
            display: flex;
            align-items: center;

            a {
              display: flex;
              align-items: center;
              justify-content: center;
              color: ${({ theme }) => theme.font_high};
              padding: 0 0.625rem;
              transition: all 0.25s ease-in-out;

              span {
                display: flex;
                align-items: center;
                font-weight: normal;
                color: ${({ theme }) => theme.font_high};
                font-size: 1rem;
                margin-left: 0.625rem;
                font-weight: bold;
                height: 2.25rem;
              }

              &.active,
              &:hover,
              &:focus {
                background: ${({ theme }) => theme.font_high};
                color: ${({ theme }) => theme.font_danger};

                svg {
                  fill: ${({ theme }) => theme.font_danger};
                }

                span {
                  color: ${({ theme }) => theme.font_danger};
                }
              }
            }
          }
        }
      }

      .cardSelector {
        display: flex;
        align-items: center;
        justify-content: center;

        .profile {
          display: none;
        }

        .cardIdentity {
          display: flex;
          align-items: center;
          justify-content: center;

          color: ${({ theme }) => theme.font_high};

          span {
            margin-left: 0.625rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    ${({ open }) =>
      open &&
      css`
        margin-top: 5.31rem;
      `}

    .content {
      ${({ open }) =>
        open &&
        css`
          position: fixed;
          top: 0;
          left: 0;
          z-index: 100;
        `}

      .top {
        justify-content: center;

        .hamburger {
          display: flex;
        }

        .left {
          .title {
            display: none;
          }
        }

        .right {
          position: absolute;
          top: 1.563rem;
          right: 0.625rem;

          .profile {
            display: none;
          }
        }
      }

      .navigation {
        position: fixed;
        top: 5.31rem;
        left: 0;
        width: 100%;
        height: calc(100vh - 5.31rem);
        z-index: 100;
        padding: 1.563rem;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        transform: ${({ open }) =>
          open ? 'translateX(0)' : 'translateX(-100%)'};
        transition: transform 0.3s ease-in-out;

        nav {
          ul {
            display: flex;
            flex-direction: column;
            gap: 1.25rem;

            li {
              a {
                border-radius: 0.25rem;
              }
            }
          }
        }

        .cardSelector {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 1.875rem;

          .profile {
            display: flex;
          }
        }
      }
    }
  }
`;
