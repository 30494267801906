import axios from 'axios';

// Store import
import { store } from '../store';
import { signOut } from '../store/modules/auth/actions';

// Create axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  successResponse => successResponse,
  errorResponse => {
    // When 401 error, sign out
    if (errorResponse.response.status === 401) store.dispatch(signOut());
    return Promise.reject(errorResponse);
  },
);

export default api;
