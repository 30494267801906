import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;

  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    margin-top: 0.625rem;

    div.content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-self: stretch;

      h2 {
        text-align: center;
        margin-bottom: 1.25rem;
      }

      .page {
        .loading {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        p,
        h3,
        li,
        .text {
          padding: 0.9375rem 9.375rem;

          @media screen and (max-width: 1000px) {
            padding: 0.9375rem;
          }
        }

        li {
          margin-left: 1.875rem;
        }
        .contractBox {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0.9375rem 9.375rem;
          @media screen and (max-width: 900px) {
            padding: 0.9375rem;
          }

          .contractContent {
            max-width: 62.5rem;
            border: 0.125rem solid #ccc;
            overflow: scroll;
            max-height: 18.75rem;
            width: 65.5rem;
            padding: 1.25rem;

            p {
              padding: 0.9375rem;
            }

            @media screen and (max-width: 900px) {
              width: auto;
            }
          }
        }

        .button-area {
          display: flex;
          justify-content: space-around;
          align-items: center;
          margin: 1.875rem 0;

          @media screen and (max-width: 1000px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1.875rem;
          }
        }
      }
    }
  }
`;
