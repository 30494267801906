import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';

// Setup reactotron
import './config/reactotron';

// Theme import
import lightTheme from './styles/themes/light';
import GlobalStyle from './styles/global';

// Config import
import toastConfig from './config/toast';

// Route improt
import Routes from './routes';

// Store import
import { store, persistor } from './store';

const App: React.FC = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <ToastContainer {...toastConfig} />
        <GlobalStyle />
        <ThemeProvider theme={lightTheme}>
          <Routes />
        </ThemeProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

export default App;
