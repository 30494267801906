import styled, { css } from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { rgba } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 80%;

  @media screen and (max-width: 900px) {
    max-width: 95%;
  }
`;

export const StepsScrollBar = styled(PerfectScrollbar)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;

  padding: 0 1.563rem 3.125rem 1.563rem;
`;

export const Steps = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;

  gap: 0 3.125rem;

  position: relative;
`;

interface IStepProps {
  stepNumber: number;
  activeStep: number;
  children: React.ReactNode;
}
export const Step = styled.div<IStepProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 50%;
    ${props =>
      props.stepNumber > props.activeStep &&
      css`
        background: ${props.theme.background_secondary};
      `};
    ${props =>
      props.stepNumber === props.activeStep &&
      css`
        background: ${props.theme.font_warning};
      `};
    ${props =>
      props.stepNumber < props.activeStep &&
      css`
        background: ${props.theme.background_primary};
      `};
  }

  > span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 110%;
    transform: translateX(-50%);
    width: 200%;

    > p {
      color: ${props =>
        props.stepNumber <= props.activeStep
          ? props.theme.font_primary
          : props.theme.font_secondary};
      ${props =>
        props.stepNumber <= props.activeStep &&
        css`
          font-weight: 600;
        `}
    }
  }

  @media screen and (max-width: 500px) {
    ${props =>
      props.stepNumber !== props.activeStep &&
      Math.abs(props.stepNumber - props.activeStep) > 1 &&
      css`
        display: none;
      `}
  }
`;

export const ProgressBar = styled.div`
  width: 95%;
  height: 0.1875rem;
  background: ${({ theme }) => theme.background_secondary};
  position: absolute;
  top: 1.563rem;
  left: 50%;
  transform: translateX(-50%);

  margin: 0px auto;
`;

interface IProgressProps {
  size: number;
}
export const Progress = styled.div<IProgressProps>`
  height: 100%;
  width: ${props => `${props.size}%`};
  background: ${({ theme }) => theme.background_primary};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  margin-top: 3.125rem;

  .box {
    max-width: 93.75rem;
    width: 100%;
    background-color: ${({ theme }) => theme.background_high};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.9375rem;
    margin: 0.625rem 0;
    border-radius: 0.3125rem;
    filter: ${props => css`
        drop-shadow(0px 0.0625rem 0.125rem ${rgba(
          props.theme.background_low,
          0.25,
        )})
        `};
  }
`;

export const Navigation = styled.div`
  background-color: ${({ theme }) => theme.background_high};
  display: flex;
  flex-direction: column;
  padding: 0.9375rem;
  margin: 0.625rem 0;
  border-radius: 0.3125rem;
  max-width: 93.75rem;
  width: 100%;
  filter: ${props => css`
  drop-shadow(0px 0.0625rem 0.125rem ${rgba(props.theme.background_low, 0.25)})
  `};

  nav {
    display: flex;
    justify-content: space-between;
    align-self: stretch;
    align-items: center;
    gap: 1.875rem;
  }

  p {
    text-align: center;
  }

  @media screen and (max-width: 500px) {
    nav {
      display: flex;
      flex-direction: column;
      align-items: center;

      button {
        align-self: stretch;
      }
    }
  }
`;
