import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;

  span {
    font-weight: 500;
    color: ${({ theme }) => theme.font_warning};
  }
`;

export const GiftContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: center;
  justify-content: center;

  h2 {
    color: ${({ theme }) => theme.font_danger};
    font-size: 2.25rem;
    margin-bottom: 1.438rem;
    text-align: center;
  }

  p {
    font-size: 1.125rem;
    color: ${({ theme }) => theme.font_secondary};
    text-align: center;
  }

  > button {
    margin-top: 2.188rem;
    max-width: 70vw;
  }
`;
