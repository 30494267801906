import React, { useState, createContext, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

// Store import
import { useTranslation } from 'react-i18next';
import { IApplicationState } from '../../store';
import { IAuthState } from '../../store/modules/auth/types';

// Service import
import api from '../../services/api';

// Interfaces
interface ICardCountEach {
  [key: number]: number;
}

interface ICardContextData {
  card_counts: ICardCountEach;
  models_loading: boolean;

  active_page: number;
  set_active_page(page: 0 | 1 | 2 | 3 | 4): void;

  card_preview: string | null;
  set_card_preview(base64: string | null): void;

  selected_card_model: number;
  set_selected_card_model(model: number): void;

  selected_card_color: number;
  set_selected_card_color(color: number): void;

  phone_show: boolean;
  set_phone_show(show: boolean): void;

  phone_order: number;
  set_phone_order(order: number): void;

  whatsapp_show: boolean;
  set_whatsapp_show(show: boolean): void;

  whatsapp_order: number;
  set_whatsapp_order(order: number): void;

  email_show: boolean;
  set_email_show(show: boolean): void;

  email_order: number;
  set_email_order(order: number): void;

  website_show: boolean;
  set_website_show(show: boolean): void;

  website_order: number;
  set_website_order(order: number): void;

  facebook_show: boolean;
  set_facebook_show(show: boolean): void;

  facebook_order: number;
  set_facebook_order(order: number): void;

  linkedin_show: boolean;
  set_linkedin_show(show: boolean): void;

  linkedin_order: number;
  set_linkedin_order(order: number): void;

  twitter_show: boolean;
  set_twitter_show(show: boolean): void;

  twitter_order: number;
  set_twitter_order(order: number): void;

  instagram_show: boolean;
  set_instagram_show(show: boolean): void;

  instagram_order: number;
  set_instagram_order(order: number): void;

  youtube_show: boolean;
  set_youtube_show(show: boolean): void;

  youtube_order: number;
  set_youtube_order(order: number): void;

  address_show: boolean;
  set_address_show(show: boolean): void;

  address_order: number;
  set_address_order(order: number): void;
}

export const CardContext = createContext<ICardContextData>(
  {} as ICardContextData,
);

const CardProvider: React.FC = ({ children }) => {
  // Global states
  const user_data = useSelector<IApplicationState>(
    state => state.auth,
  ) as IAuthState;

  // Get translation function
  const { t } = useTranslation();

  // History to navigation
  const history = useHistory();

  // Local states
  const [card_counts, set_card_counts] = useState<ICardCountEach>({});
  const [models_loading, set_card_count_loading] = useState(true);

  // Load model count from API
  useEffect(() => {
    const loadModelCount = async () => {
      try {
        const response = await api.get('card/models');
        set_card_counts(response.data.each);
        set_card_count_loading(false);
      } catch (err) {
        if (err.response?.data?.message) toast.error(err.response.data.message);
        else
          toast.error(
            t(
              '@general/CONNECTION_FAILURE_ERROR',
              'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
            ),
          );
        set_card_count_loading(true);
      }
    };
    loadModelCount();
  }, [t]);

  // Redirect user to dashboard if card not created
  if (user_data.user?.card_created_at || user_data.user?.trial_expires_at)
    history.push('/dashboard');

  // Context states
  const [active_page, set_active_page] = useState<0 | 1 | 2 | 3 | 4>(0);
  const [card_preview, set_card_preview] = useState<string | null>(null);
  const [selected_card_model, set_selected_card_model] = useState(1);
  const [selected_card_color, set_selected_card_color] = useState(0);
  const [phone_show, set_phone_show] = useState(true);
  const [phone_order, set_phone_order] = useState(0);
  const [whatsapp_show, set_whatsapp_show] = useState(true);
  const [whatsapp_order, set_whatsapp_order] = useState(1);
  const [email_show, set_email_show] = useState(true);
  const [email_order, set_email_order] = useState(2);
  const [website_show, set_website_show] = useState(true);
  const [website_order, set_website_order] = useState(3);
  const [facebook_show, set_facebook_show] = useState(true);
  const [facebook_order, set_facebook_order] = useState(4);
  const [linkedin_show, set_linkedin_show] = useState(true);
  const [linkedin_order, set_linkedin_order] = useState(5);
  const [twitter_show, set_twitter_show] = useState(true);
  const [twitter_order, set_twitter_order] = useState(6);
  const [instagram_show, set_instagram_show] = useState(true);
  const [instagram_order, set_instagram_order] = useState(7);
  const [youtube_show, set_youtube_show] = useState(true);
  const [youtube_order, set_youtube_order] = useState(8);
  const [address_show, set_address_show] = useState(true);
  const [address_order, set_address_order] = useState(9);

  return (
    <CardContext.Provider
      value={{
        card_counts,
        models_loading,
        active_page,
        set_active_page: page => set_active_page(page),
        card_preview,
        set_card_preview: base64 => set_card_preview(base64),
        selected_card_model,
        set_selected_card_model: model => set_selected_card_model(model),
        selected_card_color,
        set_selected_card_color: color => set_selected_card_color(color),
        phone_show,
        set_phone_show: show => set_phone_show(show),
        phone_order,
        set_phone_order: order => set_phone_order(order),
        whatsapp_show,
        set_whatsapp_show: show => set_whatsapp_show(show),
        whatsapp_order,
        set_whatsapp_order: order => set_whatsapp_order(order),
        email_show,
        set_email_show: show => set_email_show(show),
        email_order,
        set_email_order: order => set_email_order(order),
        website_show,
        set_website_show: show => set_website_show(show),
        website_order,
        set_website_order: order => set_website_order(order),
        facebook_show,
        set_facebook_show: show => set_facebook_show(show),
        facebook_order,
        set_facebook_order: order => set_facebook_order(order),
        linkedin_show,
        set_linkedin_show: show => set_linkedin_show(show),
        linkedin_order,
        set_linkedin_order: order => set_linkedin_order(order),
        twitter_show,
        set_twitter_show: show => set_twitter_show(show),
        twitter_order,
        set_twitter_order: order => set_twitter_order(order),
        instagram_show,
        set_instagram_show: show => set_instagram_show(show),
        instagram_order,
        set_instagram_order: order => set_instagram_order(order),
        youtube_show,
        set_youtube_show: show => set_youtube_show(show),
        youtube_order,
        set_youtube_order: order => set_youtube_order(order),
        address_show,
        set_address_show: show => set_address_show(show),
        address_order,
        set_address_order: order => set_address_order(order),
      }}
    >
      {children}
    </CardContext.Provider>
  );
};

function useCard(): ICardContextData {
  // Use the context
  const context = useContext(CardContext);
  return context;
}

export { CardProvider, useCard };
