import { AnyAction } from 'redux';

// Type import
import {
  IStatisticsCount,
  IUpdateStatisticsSuccessAction,
  StatisticsActionTypes,
} from './types';

export function updateStatisticsRequest(): AnyAction {
  return {
    type: StatisticsActionTypes.UPDATE_STATISTICS_REQUEST,
    payload: {},
  };
}

export function updateStatisticsSuccess(
  payload: IStatisticsCount,
): IUpdateStatisticsSuccessAction {
  return {
    type: StatisticsActionTypes.UPDATE_STATISTICS_SUCCESS,
    payload,
  };
}

export function updateStatisticsFailure(): AnyAction {
  return {
    type: StatisticsActionTypes.UPDATE_STATISTICS_FAILURE,
    payload: {},
  };
}
