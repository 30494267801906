import React from 'react';
import stc from 'string-to-color';

// Util import
import { GetUserStr } from '../../util/stringToColor';

// Style import
import { Container } from './styles';

// Interfaces
interface IProps {
  name: string;
  src?: string;
  size: number;
}

const Avatar: React.FC<IProps> = ({ name, src, size }) => {
  return (
    <Container color={stc(name)} size={size} src={src}>
      {src ? <img src={src} alt={src} /> : <span>{GetUserStr(name)}</span>}
    </Container>
  );
};

Avatar.defaultProps = {
  src: '',
};

export default Avatar;
