import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

// Route import
import Route from './Route';

// Page import
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Card from '../pages/Card';
import Cards from '../pages/Cards';
import Statistics from '../pages/Statistics';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Login} />
    <Route path="/dashboard" isPrivate exact component={Dashboard} />
    <Route path="/card" isPrivate exact component={Card} />
    <Route path="/cards" isPrivate exact component={Cards} />
    <Route path="/statistics/:id?" isPrivate component={Statistics} />
    <Redirect to="/" />
  </Switch>
);

export default Routes;
