import React, { useRef } from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import { MdOpenInNew, MdOpenWith } from 'react-icons/md';
import Checkbox from '@material-ui/core/Checkbox';

// Component import
import Input from '../../../components/Form/Input';

// Constants
const ItemTypes = {
  ITEM: 'item',
};

const style = {
  border: '0.0625rem dashed gray',
  padding: '0.5rem',
  backgroundColor: 'white',
  cursor: 'move',
  display: 'flex',
  marginBottom: '0.9375rem',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '0.625rem',
  touchAction: 'none',
};

const link = {
  marginTop: '0.25rem',
  color: '#666666',
};

// Interfaces
export interface IItemProps {
  id: number;
  name: string;
  label: string;
  visible: boolean;
  value?: string;
  setVisible: (visible: boolean) => void;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  setAdjusted: (adjust: boolean) => void;
}

interface IDragObject {
  id: string;
  index: number;
  type: string;
}

export const DragItem: React.FC<IItemProps> = ({
  id,
  label,
  value,
  visible,
  setVisible,
  index,
  moveItem,
  setAdjusted,
}) => {
  // Open link on new window
  const accessExternalLink = () => {
    window.open(value, '_blank');
  };

  // Local refs
  const ref = useRef<HTMLDivElement>(null);

  // Drop item hook
  const [, drop] = useDrop({
    accept: ItemTypes.ITEM,
    hover(item: IDragObject, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);
      setAdjusted(true);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  // Is dragging hook
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.ITEM, id, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // Opacity
  const opacity = isDragging ? 0 : 1;

  // Drag and drop event
  drag(drop(ref));

  return (
    <div ref={ref} style={{ ...style, opacity }}>
      <Checkbox
        color="primary"
        checked={visible}
        onChange={(_, checked) => {
          setVisible(checked);
          setAdjusted(true);
        }}
      />
      <MdOpenWith size={24} />
      <button style={{ ...link }} type="button" onClick={accessExternalLink}>
        <MdOpenInNew size={24} />
      </button>
      <Input
        value={value || ''}
        standalone
        label={label}
        variant="outlined"
        size="small"
        disabled
      />
    </div>
  );
};
