export const AuthActionTypes = {
  SIGN_IN_REQUEST: '@auth/SIGN_IN_REQUEST',
  SESSION_RESUME_REQUEST: '@auth/SESSION_RESUME_REQUEST',
  SESSION_REFRESH_SUCCESS: '@auth/SESSION_REFRESH_SUCCESS',
  SIGN_IN_SUCCESS: '@auth/SIGN_IN_SUCCESS',
  SIGN_OUT: '@auth/SIGN_OUT',
  REFRESH_SESSION: '@auth/REFRESH_SESSION',
  SIGN_FAILURE: '@auth/SIGN_FAILURE',
};

export interface ISignInRequestAction {
  type: typeof AuthActionTypes.SIGN_IN_REQUEST;
  payload: {
    cpf: string;
    password: string;
  };
}

export interface ISignInSuccessAction {
  type: typeof AuthActionTypes.SIGN_IN_SUCCESS;
  payload: ISessionData;
}

export interface IUserEmail {
  id: number;
  fk_pessoa: number;
  b_principal: number;
  s_nome: string;
  s_email: string;
  s_observacao: string;
}

export interface IUserFile {
  id: string;
  path: string;
  created_at: Date;
  updated_at: Date;
}

export interface IPerson {
  id: number;
  fk_pessoa_pai: number;
  fk_categoria: number;
  fk_profissao: number;
  fk_unidade: number;
  b_bloqueado: number;
  i_tipo: number;
  s_cia: string;
  s_codigo: string;
  s_cpf: string;
  b_ativo: number;
  s_nome: string;
  s_apelido: string;
  i_sexo: number;
  d_nascimento: Date;
  i_estado_civil: number;
  s_naturalidade: string;
  s_nacionalidade: string;
  i_grupo_sanguineo: number;
  i_grau_instrucao: number;
  i_etnia: number;
  x_observacao: string;
  i_doador: number;
  s_grau_parentesco: string;
  b_deficiencia_fisica: number;
  b_deficiencia_auditiva: number;
  b_deficiencia_visual: number;
  b_deficiencia_mental: number;
  b_deficiencia_intelectual: number;
  b_deficiencia_reabilitado: number;
  b_foto_perfil: number;
  b_foto_cartao: number;
  x_log: string;
  s_avatar: string;
  d_formulario_dependente: string;
  emails?: IUserEmail[];
  occupation?: {
    id: number;
    s_nome: string;
    s_codigo: string;
  };
}

export interface IUser {
  id: string;
  person_id: number;

  name?: string;
  specialty?: string;
  phone?: string;

  whatsapp?: string;
  facebook?: string;
  linkedin?: string;
  twitter?: string;
  instagram?: string;
  youtube?: string;
  website?: string;

  address_zipcode?: string;
  address_street?: string;
  address_number?: string;
  address_neighborhood?: string;
  address_city?: string;
  address_state?: string;

  card_created_at?: string; // Need parseISO
  trial_expires_at?: string; // Need parseISO
  gift_opened_at?: string; // Need parseISO

  card_phone_show?: boolean;
  card_phone_order?: number;

  card_email_show?: boolean;
  card_email_order?: number;

  card_whatsapp_show?: boolean;
  card_whatsapp_order?: number;

  card_address_show?: boolean;
  card_address_order?: number;

  card_website_show?: boolean;
  card_website_order?: number;

  card_facebook_show?: boolean;
  card_facebook_order?: number;

  card_linkedin_show?: boolean;
  card_linkedin_order?: number;

  card_twitter_show?: boolean;
  card_twitter_order?: number;

  card_instagram_show?: boolean;
  card_instagram_order?: number;

  card_youtube_show?: boolean;
  card_youtube_order?: number;

  logo?: IUserFile;
  logo_id?: string;
  logo_url?: string;

  selected_card_id?: string;

  created_at?: string; // Need parseISO
  updated_at?: string; // Need parseISO
}

export interface ISessionData {
  token: string;
  expires_at: string; // New parseISO
  person: IPerson;
  user: IUser;
}

export interface IAuthState {
  updated: Date;
  signed: boolean;
  loading: boolean;

  token?: string;
  expires_at?: Date;
  user?: IUser;
  person?: IPerson;
}
