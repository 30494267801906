import styled, { css } from 'styled-components';

interface IContainerProps {
  hide: boolean | undefined;
  disabled?: boolean;
  readOnly?: boolean;
  error?: boolean;
  startAdornment?: boolean;
  children: React.ReactNode;
}

export const Container = styled.div<IContainerProps>`
  display: ${props => (props.hide ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-bottom: 0.625rem;

  width: 100%;

  border-radius: 0.3125rem;

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;

      * {
        cursor: not-allowed;
      }
    `}

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    align-self: stretch;

    background: ${({ theme }) => theme.background_high};
    border-radius: 0.3125rem;
    border: ${props => `0.125rem solid ${props.theme.background_high}`};

    > span {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 3rem;

      &.startAdornment {
        background: ${({ theme }) => theme.background_primary};
        border-top-left-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem;
        width: 2.875rem;

        ${props =>
          props.error &&
          css`
            background: ${props.theme.background_danger};
          `}
      }

      &.endAdornment {
        margin-right: 0.3125rem;
      }
    }

    input {
      flex: 1;
      height: 3rem;
      border-radius: 0.3125rem;
      border: 0;
      margin: 0;
      padding: 0;
      font-size: 0.875rem;
      padding: 0 1rem;
      background: transparent;

      ${props =>
        props.startAdornment &&
        css`
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        `}

      &::placeholder {
        color: ${({ theme }) => theme.font_secondary};
      }
    }
  }

  span.helpLabel {
    margin-top: 0.3125rem;
    color: ${({ theme }) => theme.font_high};
  }
`;
