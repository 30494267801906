import React, { useState, useRef, useContext, useCallback } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { ThemeContext } from 'styled-components';
import {
  MdPermIdentity,
  MdLockOutline,
  MdVisibilityOff,
  MdVisibility,
} from 'react-icons/md';
import { Form } from '@unform/web';
import { FormHandles, SubmitHandler } from '@unform/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Component import
import Button from '../../components/Form/Button';
import CustomInput from '../../components/Form/CustomInput';

// Style import
import { Container, Header, Content, Footer } from './styles';

// Store import
import { signInRequest } from '../../store/modules/auth/actions';
import { IApplicationState } from '../../store';

// Interfaces
interface ILogin {
  cpf: string;
  password: string;
}

const Login: React.FC = () => {
  // Theme context
  const themeContext = useContext(ThemeContext);

  // Get translation function
  const { t } = useTranslation();

  // Local states
  const [passwordVisible, setPasswordVisible] = useState(false);

  // Global states
  const loading = useSelector((state: IApplicationState) => state.auth.loading);

  // Local refs
  const formRef = useRef<FormHandles>(null);

  // Dispatch hook
  const dispatch = useDispatch();

  // Submit data
  const handleSubmit: SubmitHandler<ILogin> = useCallback(
    async data => {
      // Clear form errors
      if (formRef.current) formRef.current.setErrors({});

      // Setup a schema to be validated
      const schema = Yup.object()
        .shape({
          cpf: Yup.string()
            .strict()
            .typeError(
              t(
                '@login/INVALID_CPF_TYPE',
                'O CPF deve ser composto por caracteres alfanuméricos.',
              ),
            )
            .required(t('@login/MISSING_CPF', 'Informe o CPF.')),
          password: Yup.string()
            .strict()
            .typeError(
              t(
                '@login/INVALID_PASSWORD_TYPE',
                'A senha deve ser composta por caracteres alfanuméricos.',
              ),
            )
            .required(t('@login/MISSING_PASSWORD', 'Informe uma senha.')),
        })
        .noUnknown(true, t('@login/INVALID_REQUEST', 'Requisição inválida.'));

      try {
        // Validates the schema
        await schema.validate(data, {
          abortEarly: false,
          stripUnknown: false,
        });

        // Send request
        dispatch(signInRequest(data.cpf, data.password));
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const validationErrors: { [key: string]: string } = {};
          const errors: string[] = [];
          err.inner.forEach(error => {
            errors.push(error.message);
            if (error.path) validationErrors[error.path] = error.message;
          });
          if (formRef.current) formRef.current.setErrors(validationErrors);
          toast.error(errors[0]);
        }
      }
    },
    [t, dispatch],
  );

  return (
    <Container>
      <Header>
        <img
          src={process.env.REACT_APP_CLIENT_LOGO_SM_WHITE_URL}
          alt={t('@login/PALM_HAND', 'Na Palma da Mão')}
        />
        <h1>{t('@login/TITLE', 'Cartão Digital')}</h1>
      </Header>
      <Content>
        <div className="item">
          <h1>
            {t(
              '@login/DESCRIPTION',
              'Um presente da {{ client }} para você e para seus dependentes',
              {
                client: process.env.REACT_APP_CLIENT_SHORT_NAME,
              },
            )}
          </h1>
        </div>
        <div className="item">
          <div className="instructions">
            {t(
              '@login/INSTRUCTIONS',
              'Identifique-se, informando seu CPF e sua senha (a mesma senha utilizada no APP {{ app }}).',
              {
                app: process.env.REACT_APP_CLIENT_APP_NAME,
              },
            )}
          </div>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <CustomInput
              name="cpf"
              mask="999.999.999-99"
              placeholder={t('@login/CPF', 'CPF')}
              startAdornment={
                <MdPermIdentity size={35} color={themeContext.font_high} />
              }
              disabled={loading}
            />
            <CustomInput
              type={passwordVisible ? 'text' : 'password'}
              name="password"
              placeholder={t('@login/PASSWORD', 'Senha')}
              startAdornment={
                <MdLockOutline size={35} color={themeContext.font_high} />
              }
              endAdornment={
                <button
                  type="button"
                  onClick={() => {
                    if (!loading) setPasswordVisible(!passwordVisible);
                  }}
                >
                  {passwordVisible ? (
                    <MdVisibilityOff
                      color={themeContext.font_secondary}
                      size={25}
                    />
                  ) : (
                    <MdVisibility
                      color={themeContext.font_secondary}
                      size={25}
                    />
                  )}
                </button>
              }
              disabled={loading}
            />
            <Button
              colorType="primary"
              size="large"
              type="submit"
              loading={loading}
            >
              {t('@login/LOGIN', 'Entrar')}
            </Button>
          </Form>
        </div>
      </Content>

      <Footer>
        <img
          src={process.env.REACT_APP_CLIENT_BRAND_LG_WHITE_URL}
          alt={process.env.REACT_APP_CLIENT_LONG_NAME}
        />
        <div className="red-strip" />
        <div className="white-strip">
          <span>{t('@login/DEVELOPED_BY', 'Desenvolvido por VetorWEB')}</span>
        </div>
      </Footer>
    </Container>
  );
};

export default Login;
