export const CardActionTypes = {
  UPDATE_CARD_LIST_REQUEST: '@card/UPDATE_CARD_LIST_REQUEST',
  UPDATE_CARD_LIST_SUCCESS: '@card/UPDATE_CARD_LIST_SUCCESS',
  UPDATE_CARD_LIST_FAILURE: '@card/UPDATE_CARD_LIST_FAILURE',
};

export interface ICard {
  id: string;
  user_id: string;

  template: number;

  color: number;
  archived: boolean;

  name?: string;
  occupation?: string;
  specialty?: string;
  document?: string;

  phone?: string;
  phone_show: boolean;
  phone_order: number;

  whatsapp?: string;
  whatsapp_code?: string;
  whatsapp_show: boolean;
  whatsapp_order: number;

  email?: string;
  email_show: boolean;
  email_order: number;

  address_zipcode?: string;
  address_street?: string;
  address_number?: string;
  address_neighborhood?: string;
  address_city?: string;
  address_state?: string;

  address?: string;
  address_code?: string;
  address_show: boolean;
  address_order: number;

  website?: string;
  website_code?: string;
  website_show: boolean;
  website_order: number;

  facebook?: string;
  facebook_code?: string;
  facebook_show: boolean;
  facebook_order: number;

  linkedin?: string;
  linkedin_code?: string;
  linkedin_show: boolean;
  linkedin_order: number;

  twitter?: string;
  twitter_code?: string;
  twitter_show: boolean;
  twitter_order: number;

  instagram?: string;
  instagram_code?: string;
  instagram_show: boolean;
  instagram_order: number;

  youtube?: string;
  youtube_code?: string;
  youtube_show: boolean;
  youtube_order: number;

  logo_id: string;
  logo_url: string;
  logo_base64: string;

  created_at: string; // Need parseISO
  updated_at: string; // Need parseISO
}

export interface IUpdateCardListSuccessAction {
  type: typeof CardActionTypes.UPDATE_CARD_LIST_SUCCESS;
  payload: {
    cards: ICard[];
  };
}

export interface ICardState {
  list: ICard[];
  updated: Date;
  loading: boolean;
  refreshing: boolean;
}
