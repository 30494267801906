import styled, { css } from 'styled-components';

interface IItemColorProps {
  isClicked?: boolean;
  colors: string[];
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;

  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    margin-top: 0.625rem;

    div.content {
      display: grid;
      max-width: 93.75rem;
      width: 100%;
      grid-template-columns: 3fr 2fr;
      gap: 1.25rem;

      .title {
        strong {
          font-size: 1rem;
        }
      }

      .count {
        font-size: 0.8rem;
        margin-bottom: 1.25rem;
      }

      .subtitle {
        font-size: 1rem;
        margin-top: 1.25rem;
        text-align: center;
      }

      .select-container {
        width: 100%;
      }

      .box-preview {
        .tip-message {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1;
        }
      }

      @media screen and (max-width: 1200px) {
        grid-template-columns: minmax(0, 1fr);
      }
    }
  }
`;

export const GalleryContainer = styled.div`
  margin-top: 1.25rem;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  justify-items: center;
  align-items: center;
  align-self: stretch;

  @media screen and (max-width: 1500px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media screen and (max-width: 700px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: minmax(0, 1fr);
  }
`;

export const GalleryItem = styled.button<{ selected?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => (props.selected ? '#f4f4f4' : '#ffffff')};
  padding: 0.625rem;
  width: 100%;

  &:hover {
    background-color: #f4f4f4;
    cursor: pointer;
  }

  .item-name {
    margin: 0.625rem 0;
    text-align: center;
  }

  .colors-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0.1875rem;
  }

  img {
    width: 100%;
    max-width: 70vw;
  }
`;

export const ItemColor = styled.button<IItemColorProps>`
  width: 1.5rem;
  height: 1.5rem;
  background-color: ${props => props.colors[0]};
  border: 0.1875rem solid #fff;

  ${props =>
    props.isClicked &&
    css`
      border-color: #000;
    `}

  &.two-colors {
    background-image: ${props =>
      `-webkit-linear-gradient(45deg, ${props.colors[0]} 50%, ${props.colors[1]} 50%)`};
  }
`;

export const PreviewContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  gap: 1.875rem;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const ModelData = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  .details {
    h2 {
      margin: 0 0 1.875rem 0;
    }
    div {
      margin: 0.3125rem 0;
    }
  }

  .nav-color {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1.25rem !important;
    gap: 0.3125rem;
  }

  .note {
    font-size: 0.8125rem;
  }

  @media screen and (max-width: 1200px) {
    align-items: center;
    justify-content: center;
    text-align: center;

    .nav-color {
      align-items: center;
      justify-content: center;
    }
  }
`;

export const ModelPreview = styled.div`
  text-align: center;
  flex: 0.9;
  justify-self: stretch;

  img {
    width: 18.75rem;
    max-width: 70vw;
  }

  div {
    text-align: center;
    font-size: 0.8125rem;
    color: #aaa;
  }
`;
