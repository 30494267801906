/* eslint-disable no-console */
import { Store } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

// Store import
import createStore from './createStore';

// Persistor import
import persistReducer from './persistReducer';

// Type iomport
import { IAuthState } from './modules/auth/types';
import { ICardState } from './modules/card/types';
import { IStatisticsState } from './modules/statistics/types';

// Root import
import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';

// Setup saga
const sagaMonitor =
  process.env.NODE_ENV === 'development'
    ? console.tron.createSagaMonitor()
    : null;

// Handle saga
const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
const middlewares = [sagaMiddleware];

// Application store
export interface IApplicationState {
  auth: IAuthState;
  card: ICardState;
  statistics: IStatisticsState;
}

// Create store
export const store: Store<IApplicationState> = createStore(
  persistReducer(rootReducer),
  middlewares,
);

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
