import { Reducer } from 'redux';
import { produce } from 'immer';
import { parseISO } from 'date-fns';

// Type import
import { AuthActionTypes, IAuthState, ISessionData } from './types';

const INITIAL_STATE: IAuthState = {
  updated: new Date(),
  signed: false,
  loading: true,

  token: undefined,
  expires_at: undefined,
  user: undefined,
  person: undefined,
};

const auth: Reducer<IAuthState> = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case AuthActionTypes.SIGN_IN_REQUEST:
      case AuthActionTypes.SESSION_RESUME_REQUEST:
        draft.loading = true;
        break;

      case AuthActionTypes.SIGN_IN_SUCCESS:
      case AuthActionTypes.SESSION_REFRESH_SUCCESS:
        {
          const payload = action.payload as ISessionData;
          draft.loading = false;
          draft.updated = new Date();
          draft.signed = true;
          draft.token = payload.token;
          draft.expires_at = parseISO(payload.expires_at);
          draft.user = payload.user;
          draft.person = payload.person;
        }
        break;

      case AuthActionTypes.SIGN_FAILURE:
      case AuthActionTypes.SIGN_OUT:
        draft.updated = new Date();
        draft.signed = false;
        draft.loading = false;
        draft.token = undefined;
        draft.expires_at = undefined;
        draft.user = undefined;
        draft.person = undefined;
        break;

      default:
    }
  });
};

export default auth;
