import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import download from 'js-file-download';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

// Interface import
import { useTranslation } from 'react-i18next';
import { IApplicationState } from '../../store';

// Component import
import Button from '../Form/Button';

// Service import
import api from '../../services/api';

// Style import
import { Container } from './styles';
import { updateCardListRequest } from '../../store/modules/card/actions';
import { refreshSession } from '../../store/modules/auth/actions';

// Interfaces
interface ISummaryProps {
  id?: string;
  hideStatistics?: boolean;
  hideMain?: boolean;
  hideArchive?: boolean;
}

const Card: React.FC<ISummaryProps> = ({
  id,
  hideStatistics,
  hideArchive,
  hideMain,
}) => {
  // History
  const history = useHistory();

  // Get translation function
  const { t } = useTranslation();

  // Dispatch hook
  const dispatch = useDispatch();

  // Global states
  const cards = useSelector((state: IApplicationState) => state.card);
  const auth = useSelector((state: IApplicationState) => state.auth);
  const statistics = useSelector(
    (state: IApplicationState) => state.statistics,
  );

  // Local card
  const selected_card = cards.list.find(card => card.id === id);

  // Local states
  const [loading, setLoading] = useState(false);

  // Download the card
  const downloadCard = useCallback(async (): Promise<void> => {
    try {
      // Loading set
      setLoading(true);
      // API call
      const response = await api.get('card/regenerate/pdf', {
        responseType: 'blob',
        params: {
          card_id: id,
        },
      });

      // Download file
      download(response.data, `${selected_card?.name || 'card'}.pdf`);

      // Loading set
      setLoading(false);
    } catch (err) {
      // Send server error to user
      if (err.response?.data?.message) toast.error(err.response.data.message);
      // Send communication error to user
      else
        toast.error(
          t(
            '@general/CONNECTION_FAILURE_ERROR',
            'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
          ),
        );
      setLoading(false);
    }
  }, [t, selected_card?.name, id]);

  // Send the card by email
  const sendMail = useCallback(async (): Promise<void> => {
    try {
      // Loading set
      setLoading(true);

      // API call
      await api.get('card/regenerate/pdf', {
        params: {
          mail: true,
          card_id: id,
        },
      });

      // Send success message to user
      toast.success(
        t(
          '@card_component/CARD_EMAIL_SENT',
          'Cartão enviado para seu e-mail. Verifique também sua caixa de spam caso não encontre-o em sua caixa de entrada.',
        ),
        {
          position: 'top-center',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );

      // Loading set
      setLoading(false);
    } catch (err) {
      // Send server error to user
      if (err.response?.data?.message) toast.error(err.response.data.message);
      // Send communication error to user
      else
        toast.error(
          t(
            '@general/CONNECTION_FAILURE_ERROR',
            'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
          ),
        );
      setLoading(false);
    }
  }, [id, t]);

  // Handle archive
  const handleArchiveUnarchive = useCallback(async (): Promise<void> => {
    try {
      // Loading set
      setLoading(true);

      // Check if card is defined
      if (!selected_card) {
        // Display error to user
        toast.error(
          t('@card_component/CARD_NOT_FOUND', 'Cartão não encontrado.'),
        );

        // Loading set
        setLoading(false);

        // Stop function
        return;
      }

      // API call
      const response = await api.patch('card/archive', {
        archive: !selected_card?.archived || false,
        card_id: id,
      });

      // Reload card
      dispatch(updateCardListRequest());

      // Send success message
      toast.success(
        response.data.archived
          ? t('@card_component/SUCCESSFULLY_ARCHIVED', 'Arquivado com sucesso.')
          : t(
              '@card_component/SUCCESSFULLY_UNARCHIVED',
              'Desarquivado com sucesso.',
            ),
      );

      // Loading set
      setLoading(false);
    } catch (err) {
      // Send server error to user
      if (err.response?.data?.message) toast.error(err.response.data.message);
      // Send communication error to user
      else
        toast.error(
          t(
            '@general/CONNECTION_FAILURE_ERROR',
            'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
          ),
        );
      setLoading(false);
    }
  }, [t, id, dispatch, selected_card]);

  // Handle selected main card
  const handleSetSelected = useCallback(async (): Promise<void> => {
    try {
      // Loading set
      setLoading(true);

      // Check if card is defined
      if (!selected_card) {
        // Display error to user
        toast.error(
          t('@card_component/CARD_NOT_FOUND', 'Cartão não encontrado.'),
        );

        // Loading set
        setLoading(false);

        // Stop function
        return;
      }

      // API call
      await api.patch('card/set-selected', {
        card_id: id,
      });

      // Reload card
      dispatch(refreshSession());

      // Send success message
      toast.success(
        t(
          '@card_component/SUCCESSFULLY_DEFINED_AS_DEFAULT',
          'Definido com sucesso.',
        ),
      );

      // Loading set
      setLoading(false);
    } catch (err) {
      // Send server error to user
      if (err.response?.data?.message) toast.error(err.response.data.message);
      // Send communication error to user
      else
        toast.error(
          t(
            '@general/CONNECTION_FAILURE_ERROR',
            'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
          ),
        );
      setLoading(false);
    }
  }, [t, id, dispatch, selected_card]);

  return (
    <Container>
      <div className="content">
        <div className="card-image">
          {selected_card?.logo_base64 ? (
            <img
              src={String(selected_card?.logo_base64)}
              alt={t('@card_component/DIGITAL_CARD_TITLE', 'Cartão Digital')}
            />
          ) : (
            <CircularProgress />
          )}
        </div>

        {statistics.loading ? (
          <span>
            <CircularProgress />
          </span>
        ) : (
          <div className="data">
            <div className="card-label">
              <div className="label">
                {t('@card_component/ACTIVATION_LABEL', 'Ativação')}
              </div>
              <div className="value small">
                {auth?.user?.card_created_at
                  ? format(parseISO(auth?.user?.card_created_at), 'dd/MM/yyyy')
                  : t('@card_component/ACTIVATION_NOT_DEFINED', 'Não definida')}
              </div>
            </div>
            <div className="card-label">
              <div className="label">
                {t('@card_component/CARD_EXPIRATION_LABEL', 'Expiração')}
              </div>
              <div className="value small">31/12/2021</div>
            </div>
            <div className="card-label">
              <div className="label">
                {t('@card_component/STATISTICS_UNTIL', 'Estatísticas até')}
              </div>
              <div className="value small">
                {auth?.user?.trial_expires_at
                  ? format(parseISO(auth?.user?.trial_expires_at), 'dd/MM/yyyy')
                  : t(
                      '@card_component/STATISTICS_UNTIL_NOT_DEFINED',
                      'Não definida',
                    )}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="actions">
        {loading || cards.loading || cards.refreshing || auth.loading ? (
          <CircularProgress />
        ) : (
          <>
            <Button
              colorType="primary"
              size="medium"
              onClick={() => downloadCard()}
              loading={
                loading || cards.loading || cards.refreshing || auth.loading
              }
            >
              {t('@card_component/DOWNLOAD_CARD', 'Download')}
            </Button>
            <Button
              onClick={() => sendMail()}
              colorType="secondary"
              size="medium"
              loading={
                loading || cards.loading || cards.refreshing || auth.loading
              }
            >
              {t('@card_component/SEND_CARD_EMAIL', 'Enviar por E-mail')}
            </Button>
            {!hideStatistics && (
              <Button
                onClick={() => history.push(`/statistics/${id}`)}
                colorType="tertiary"
                size="medium"
                loading={
                  loading || cards.loading || cards.refreshing || auth.loading
                }
              >
                {t('@card_component/GOTO_STATISTICS', 'Acessar Estatísticas')}
              </Button>
            )}
            {!hideArchive &&
              (selected_card?.archived ? (
                <Button
                  onClick={() => handleArchiveUnarchive()}
                  colorType="primary"
                  size="medium"
                  loading={
                    loading || cards.loading || cards.refreshing || auth.loading
                  }
                >
                  {t('@card_component/UNARCHIVE', 'Desarquivar')}
                </Button>
              ) : (
                <Button
                  onClick={() => handleArchiveUnarchive()}
                  colorType="secondary"
                  size="medium"
                  loading={
                    loading || cards.loading || cards.refreshing || auth.loading
                  }
                >
                  {t('@card_component/ARCHIVE', 'Arquivar')}
                </Button>
              ))}
            {!hideMain && (
              <Button
                onClick={() => handleSetSelected()}
                colorType="primary"
                size="medium"
                loading={
                  loading || cards.loading || cards.refreshing || auth.loading
                }
                disabled={auth?.user?.selected_card_id === id}
              >
                {t('@card_component/SET_AS_DEFAULT', 'Definir como Padrão')}
              </Button>
            )}
          </>
        )}
      </div>
    </Container>
  );
};

Card.defaultProps = {
  hideStatistics: false,
  hideArchive: false,
  hideMain: false,
};

export default Card;
