import { AnyAction } from 'redux';

// Type import
import { CardActionTypes, ICard, IUpdateCardListSuccessAction } from './types';

export function updateCardListRequest(): AnyAction {
  return {
    type: CardActionTypes.UPDATE_CARD_LIST_REQUEST,
    payload: {},
  };
}

export function updateCardListSuccess(
  cards: ICard[],
): IUpdateCardListSuccessAction {
  return {
    type: CardActionTypes.UPDATE_CARD_LIST_SUCCESS,
    payload: {
      cards,
    },
  };
}

export function updateCardListFailure(): AnyAction {
  return {
    type: CardActionTypes.UPDATE_CARD_LIST_FAILURE,
    payload: {},
  };
}
