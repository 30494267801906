import { createGlobalStyle } from 'styled-components';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'cropperjs/dist/cropper.css';

export default createGlobalStyle`
  * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }

    *:focus {
        outline: 0;
    }

    html {
      overflow-x: hidden;
    }

    html, body, #root {
      min-height: 100vh;
    }

    body {
        -webkit-font-smoothing: antialiased;
        background-color: #F7F7F7;
        color: #666;
    }

    body, input, button {
        font: 0.875rem 'Roboto', sans-serif;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    ul {
        list-style: none;
    }

    button {
        cursor: pointer;
        background: none;
        border: 0;
    }

    .disabled{
      background-color:#f1f1f1;
    }
`;
