import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

// Component import
import Button from '../../../components/Form/Button';
import Select from '../../../components/Form/Select';

// Style import
import {
  Container,
  GalleryContainer,
  GalleryItem,
  ItemColor,
  ModelData,
  ModelPreview,
  PreviewContainer,
} from './styles';
import { Navigation } from '../styles';

// Hook import
import { useCard } from '../../../hooks/useCard';

// Card models import
import card_models from '../../../hooks/useCard/models';

const ModelSelect: React.FC = () => {
  // Card hook
  const cardContext = useCard();

  // Get translation function
  const { t } = useTranslation();

  // Local states
  const [selectedCategory, setSelectedCategory] = useState(1);

  return (
    <Container>
      <Navigation>
        <nav>
          <Button disabled colorType="tertiary" size="small">
            {t('@model_select_step/RETURN', 'Voltar')}
          </Button>
          <p>
            {t(
              '@model_select_step/HEADER_INSTRUCTIONS',
              'Selecione o modelo de cartão que mais combina com você. Em seguida, selecione sua cor preferida.',
            )}
          </p>
          <Button
            colorType="tertiary"
            size="small"
            disabled={
              cardContext.selected_card_model === undefined ||
              cardContext.selected_card_color === undefined
            }
            onClick={() => cardContext.set_active_page(1)}
          >
            {t('@model_select_step/NEXT', 'Avançar')}
          </Button>
        </nav>
      </Navigation>
      <main>
        {card_models?.categories?.length &&
        !cardContext.models_loading &&
        card_models?.models?.length ? (
          <div className="content">
            <div className="box">
              <Select
                options={card_models.categories}
                label={t(
                  '@model_select_step/SELECT_GALLERY',
                  'Selecione a Galeria',
                )}
                value={selectedCategory}
                onChange={value => setSelectedCategory(value)}
              />

              <div className="subtitle">
                {t('@model_select_step/SELECT_MODEL', 'Selecione o modelo')}
              </div>

              <GalleryContainer>
                {card_models.models
                  .filter(model => model.category === selectedCategory)
                  .map(model => (
                    <GalleryItem
                      key={uuidv4()}
                      type="button"
                      onClick={() => {
                        const index = card_models?.models.findIndex(
                          option => option.id === model.id,
                        );
                        if (index !== -1) {
                          cardContext.set_selected_card_color(0);
                          cardContext.set_selected_card_model(index);
                        } else
                          toast.error(
                            t(
                              '@model_select_step/MODEL_NOT_FOUND',
                              'Modelo não encontrado.',
                            ),
                          );
                      }}
                    >
                      <img src={model.imageUrl} alt={model.name} />
                      <div className="item-name">{model.name}</div>
                    </GalleryItem>
                  ))}
              </GalleryContainer>
            </div>
            <div className="box">
              {cardContext.selected_card_model !== null ? (
                <>
                  <div className="title">
                    {card_models.categories[selectedCategory].label} -{' '}
                    <strong>
                      {card_models.models[cardContext.selected_card_model].name}
                    </strong>
                  </div>

                  <div className="count">
                    {!!card_models.models[cardContext.selected_card_model] && (
                      <>
                        {t('@model_select_step/USED_BY', 'Utilizado por')}{' '}
                        {
                          cardContext.card_counts[
                            card_models.models[cardContext.selected_card_model]
                              .id
                          ]
                        }
                      </>
                    )}
                  </div>

                  <PreviewContainer>
                    <ModelData>
                      <div className="details">
                        <div>
                          {t(
                            '@model_select_step/SELECT_COLOR',
                            'Selecione a cor',
                          )}
                          <div className="nav-color">
                            {card_models.models[
                              cardContext.selected_card_model
                            ].colors.map(
                              item =>
                                item.color.length === 1 && (
                                  <ItemColor
                                    key={uuidv4()}
                                    isClicked={
                                      card_models.models[
                                        cardContext.selected_card_model
                                      ].colors[cardContext.selected_card_color]
                                        .id === item.id
                                    }
                                    onClick={() =>
                                      cardContext.set_selected_card_color(
                                        card_models.models[
                                          cardContext.selected_card_model
                                        ].colors.findIndex(
                                          color => color.id === item.id,
                                        ),
                                      )
                                    }
                                    colors={item.color}
                                  />
                                ),
                            )}

                            {card_models.models[
                              cardContext.selected_card_model
                            ].colors.map(
                              item =>
                                item.color.length > 1 && (
                                  <ItemColor
                                    key={uuidv4()}
                                    isClicked={
                                      card_models.models[
                                        cardContext.selected_card_model
                                      ].colors[cardContext.selected_card_color]
                                        .id === item.id
                                    }
                                    onClick={() =>
                                      cardContext.set_selected_card_color(
                                        card_models.models[
                                          cardContext.selected_card_model
                                        ].colors.findIndex(
                                          color => color.id === item.id,
                                        ),
                                      )
                                    }
                                    colors={item.color}
                                    className="two-colors"
                                  />
                                ),
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="note">
                        {t(
                          '@model_select_step/FOOTER_NOTE',
                          'A composição dos elementos do cartão será realizada nas próximas etapas.',
                        )}
                      </div>
                    </ModelData>
                    <ModelPreview>
                      <img
                        src={
                          card_models.models[cardContext.selected_card_model]
                            .colors[cardContext.selected_card_color].image
                        }
                        alt={
                          card_models.models[cardContext.selected_card_model]
                            .name
                        }
                      />
                      <div>
                        {t(
                          '@model_select_step/ILLUSTRATION',
                          'Imagem Ilustrativa',
                        )}
                      </div>
                    </ModelPreview>
                  </PreviewContainer>
                </>
              ) : (
                <div className="tip-message">
                  {t(
                    '@model_select_step/SELECT_MODEL_ASIDE',
                    'Selecione um modelo ao lado',
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>{t('@model_select_step/LOADING', 'Carregando...')}</div>
        )}
      </main>
    </Container>
  );
};

export default ModelSelect;
