import React from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

// Layout import
import Layout from '../layouts/default';
import Loading from '../pages/Loading';
import { IApplicationState } from '../store';

// Theme import
import lightTheme from '../styles/themes/light';

// Interfaces
interface IChildren extends RouteProps {
  component: any;
  isPrivate?: boolean;
}

const RouteWrapper = ({
  component: Component,
  isPrivate,
  ...rest
}: IChildren) => {
  // Global states
  const signed = useSelector((state: IApplicationState) => state.auth.signed);
  const authLoading = useSelector(
    (state: IApplicationState) => state.auth.loading,
  );
  const cardLoading = useSelector(
    (state: IApplicationState) => state.card.loading,
  );

  // Loading screen
  if (authLoading || cardLoading || signed === undefined) {
    return <Loading />;
  }

  // Private pages with unauthorized user
  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }

  // Public page with authorized user
  if (signed && !isPrivate) {
    return <Redirect to="/dashboard" />;
  }

  // Private route
  if (signed && isPrivate) {
    return (
      <Route
        {...rest}
        render={routeProps => (
          <ThemeProvider theme={lightTheme}>
            <Layout>
              <Component {...routeProps} />
            </Layout>
          </ThemeProvider>
        )}
      />
    );
  }

  // Public route
  return (
    <ThemeProvider theme={lightTheme}>
      <Route component={Component} {...isPrivate} {...rest} />
    </ThemeProvider>
  );
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};

export default RouteWrapper;
