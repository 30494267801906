import { rgba } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ffffff;
  padding: 0.9375rem;
  margin: 0.625rem 0;
  border-radius: 0.3125rem;
  filter: ${({ theme }) =>
    `drop-shadow(0px 0.0625rem 0.125rem ${rgba(theme.background_low, 0.25)})`};
  height: 25rem;

  .title {
    font-size: 0.875rem;
  }
`;
