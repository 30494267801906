import { produce } from 'immer';
import { Reducer } from 'redux';

// Type import
import { AuthActionTypes } from '../auth/types';
import { IStatisticsState, StatisticsActionTypes } from './types';

const INITIAL_STATE: IStatisticsState = {
  updated: new Date(),
  loading: true,
  failure: false,
  count: {
    total: 0,
    general: {
      whatsapp: 0,
      facebook: 0,
      instagram: 0,
      twitter: 0,
      linkedin: 0,
      youtube: 0,
      website: 0,
      address: 0,
    },
    each: {},
  },
};

const statistics: Reducer<IStatisticsState> = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, draft => {
    switch (action.type) {
      case StatisticsActionTypes.UPDATE_STATISTICS_REQUEST:
        draft.updated = new Date();
        draft.loading = true;
        draft.failure = false;
        break;

      case StatisticsActionTypes.UPDATE_STATISTICS_FAILURE:
        draft.updated = new Date();
        draft.loading = false;
        draft.failure = true;
        break;

      case StatisticsActionTypes.UPDATE_STATISTICS_SUCCESS:
        draft.updated = new Date();
        draft.loading = false;
        draft.failure = false;
        draft.count = action.payload;
        break;

      case AuthActionTypes.SIGN_FAILURE:
      case AuthActionTypes.SIGN_OUT:
        draft.updated = new Date();
        draft.loading = false;
        draft.failure = false;
        draft.count = {
          total: 0,
          general: {
            whatsapp: 0,
            facebook: 0,
            instagram: 0,
            twitter: 0,
            linkedin: 0,
            youtube: 0,
            website: 0,
            address: 0,
          },
          each: {},
        };
        break;

      default:
    }
  });
};

export default statistics;
