import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;

  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    margin-top: 0.625rem;

    .content {
      display: grid;
      grid-template-columns: 1fr 3fr;
      justify-content: space-between;
      gap: 1.25rem;
      align-items: flex-start;
      max-width: 93.75rem;

      .description {
        display: block;

        p {
          padding: 0.9375rem 2.5rem;
          text-align: left;
        }
        .checkArea {
          padding: 0.9375rem 2.5rem;
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {
    main {
      .content {
        grid-template-columns: minmax(0, 1fr);

        .description {
          order: 1;

          p {
            padding: 0.3125rem;
            text-align: center;
          }

          .checkArea {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
`;

export const ModelPreview = styled.div`
  text-align: center;
  img {
    width: 80%;
    max-width: 70vw;
  }
  div {
    text-align: center;
    font-size: 0.8125rem;
    color: #aaa;
  }
`;
