import styled from 'styled-components';

interface IContainerProps {
  hide: boolean | undefined;
  children: React.ReactNode;
}

export const Container = styled.div<IContainerProps>`
  display: ${props => (props.hide ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
