import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.button`
  color: #fff;
  font-weight: 700;
  border-radius: 0.3125rem;
  transition: background-color 0.2s;
  font-size: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  &.btn-disabled {
    background-color: ${({ theme }) => theme.button_high} !important;
    color: #aaa;
    cursor: not-allowed;
  }

  &.primary {
    background-color: ${({ theme }) => theme.button_primary};
    &:hover {
      background: ${props => shade(0.2, props.theme.button_primary)};
    }
  }

  &.secondary {
    background-color: ${({ theme }) => theme.button_secondary};
    &:hover {
      background: ${props => shade(0.2, props.theme.button_secondary)};
    }
  }

  &.tertiary {
    background-color: ${({ theme }) => theme.button_success};
    &:hover {
      background: ${props => shade(0.2, props.theme.button_success)};
    }
  }

  &.denial {
    background-color: ${({ theme }) => theme.button_danger};
    &:hover {
      background: ${props => shade(0.2, props.theme.button_danger)};
    }
  }

  &.small {
    padding: 0.3125rem 1.875rem;
  }

  &.medium {
    padding: 0.625rem 1.875rem;
  }

  &.large {
    padding: 1.19rem 2.5rem;
  }
`;
