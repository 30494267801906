export const StatisticsActionTypes = {
  UPDATE_STATISTICS_REQUEST: '@statistics/UPDATE_STATISTICS_REQUEST',
  UPDATE_STATISTICS_SUCCESS: '@statistics/UPDATE_STATISTICS_SUCCESS',
  UPDATE_STATISTICS_FAILURE: '@statistics/UPDATE_STATISTICS_FAILURE',
};

export interface IUpdateStatisticsSuccessAction {
  type: typeof StatisticsActionTypes.UPDATE_STATISTICS_SUCCESS;
  payload: IStatisticsCount;
}

export interface IStatisticsCount {
  total: number;
  general: {
    whatsapp: number;
    facebook: number;
    instagram: number;
    twitter: number;
    linkedin: number;
    youtube: number;
    website: number;
    address: number;
  };
  each: {
    [key: string]: {
      type: string;
      total: number;
    };
  };
}

export interface IStatisticsState {
  updated: Date;
  loading: boolean;
  failure: boolean;
  count: IStatisticsCount;
}
