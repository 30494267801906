import styled from 'styled-components';
import { rgba } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.937rem;
  margin: 0.625rem 0;
  background-color: ${({ theme }) => theme.background_high};
  filter: ${({ theme }) =>
    `drop-shadow(0px 0.0625rem 0.125rem ${rgba(theme.background_low, 0.25)})`};
  gap: 1.25rem;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 0.3125rem;
    gap: 1.25rem;

    .card-image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18.75rem;
      min-height: 33.333rem;

      img {
        width: 18.75rem;
        max-width: 70vw;
        border-radius: 5px;
      }
    }

    .data {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
      justify-content: center;
      width: 100%;
      flex: 1;
      min-height: 1.813rem;

      .card-label {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;

        .label {
          font-size: 0.8rem;
        }

        .value {
          font-size: 1.1rem;
          font-weight: 600;
        }

        .small {
          font-size: 0.7rem;
        }
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 0.65rem;
    align-items: center;
    justify-content: center;
    min-height: 14.785rem;

    button {
      align-self: stretch;
    }
  }

  @media screen and (max-width: 1000px) {
    align-self: center;

    .content {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-self: stretch;

      .data {
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }
  }
`;
