import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

// Service import
import api from '../../../services/api';

// Action import
import {
  updateCardListFailure,
  updateCardListRequest,
  updateCardListSuccess,
} from './actions';

// Get translation function
import { t } from '../../../i18n';

// Type import
import { AuthActionTypes } from '../auth/types';
import { CardActionTypes, ICard } from './types';

// Interfaces
interface ICardImageData {
  id: string;
  file: string;
}

function readFile(blob: Blob): Promise<string> {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(String(reader.result));
    };
    reader.readAsDataURL(blob);
  });
}

export function* signInSuccessCardEffect() {
  yield put(updateCardListRequest());
}

async function fetchCards(cards: ICard[]): Promise<ICardImageData[]> {
  try {
    // Iterate over cards
    const card_files = cards.map(async (card: ICard) => {
      // Call API to fetch cards
      const card_file = await api.get('card/regenerate/png', {
        params: {
          card_id: card.id,
        },
        responseType: 'blob',
      });

      // Read blob
      const card_base64 = await readFile(card_file.data);

      return { id: card.id, file: card_base64 };
    });

    // Await all cards to be fetched
    return Promise.all(card_files);
  } catch (err) {
    // Check if server had returned a error
    if (err.response?.data?.message) toast.error(err.response.data.message);
    else
      toast.error(
        t(
          '@general/CONNECTION_FAILURE_ERROR',
          'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
        ),
      );
    return [];
  }
}

export function* updateCardListRequestEffect() {
  try {
    // Get card list
    const card_response: AxiosResponse = yield call(api.get, 'card/list');

    // Fetch card images
    const card_file_results: ICardImageData[] = yield call(
      fetchCards,
      card_response.data,
    );

    // Join card with the image
    const cards: ICard[] = card_response.data.map((card: ICard) => ({
      ...card,
      logo_base64:
        card_file_results.find(
          (file: { id: string; file: string }) => file.id === card.id,
        )?.file || undefined,
    }));

    // Handle success
    yield put(updateCardListSuccess(cards));
  } catch (err) {
    // Check if server had returned a error
    if (!err.response?.data?.message)
      toast.error(
        t(
          '@general/CONNECTION_FAILURE_ERROR',
          'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
        ),
      );
    yield put(updateCardListFailure());
  }
}

export default all([
  takeLatest(
    CardActionTypes.UPDATE_CARD_LIST_REQUEST,
    updateCardListRequestEffect,
  ),
  takeLatest(AuthActionTypes.SIGN_IN_SUCCESS, signInSuccessCardEffect),
]);
