import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;

  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    margin-top: 0.625rem;

    div.content {
      display: grid;
      max-width: 93.75rem;
      width: 100%;
      grid-template-columns: 3fr 2fr;
      gap: 1.25rem;

      @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr;
      }

      .grid-1 {
        display: grid;
        grid-template-columns: 1fr;
        align-self: stretch;
      }

      .grid-2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.9375rem;
        margin-bottom: 0.9375rem;
        align-self: stretch;

        @media screen and (max-width: 1000px) {
          grid-template-columns: 1fr;
        }
      }

      .alertContent {
        p {
          margin: 0.3125rem 0;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 0.625rem;
        }
      }

      .order-container {
        align-self: stretch;
      }
    }
  }
`;

export const DndItemContainer = styled.div`
  padding: 0px;
  margin-bottom: 0px;
`;

export const ItemColor = styled.div<{ colors: string[] }>`
  width: 1.25rem;
  height: 1.25rem;
  background-color: ${props => props.colors[0]};
`;

export const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ModelData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  align-self: stretch;

  .center-button {
    margin-bottom: 1.25rem;
    flex: 1;
  }
`;

export const ModelPreview = styled.div`
  text-align: center;
  flex: 0.7;

  div {
    text-align: center;
    font-size: 0.8125rem;
    color: #aaa;
  }

  img {
    width: 31.25rem;
    max-width: 70vw;
    border-radius: 0.25rem;
  }
`;
