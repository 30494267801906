import React, { useCallback, useState, useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import MaterialUISelect from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useField } from '@unform/core';
import { v4 as uuidv4 } from 'uuid';
import { OutlinedInput } from '@material-ui/core';

// Style import
import { Container } from './styles';

// Interfaces
interface ISelectOptions {
  value: any;
  label: any;
}

interface ISelectProps {
  name?: string | null;
  value?: any;
  standalone?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  hide?: boolean;
  required?: boolean;
  placeholder?: string | null;
  error?: string | null;

  variant?: 'filled' | 'outlined' | 'standard';
  margin?: 'normal' | 'dense' | 'none';
  fullWidth?: boolean;
  size?: 'small' | 'medium';
  options: ISelectOptions[];

  label?: string | null;
  helpLabel?: string | null;

  onChange?: (value: any) => any;
  onFocus?: (event: React.FocusEvent) => any;
}

// Material UI styles
const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      width: '100%',
    },
  }),
);

const Select: React.FC<ISelectProps> = ({
  name,
  value: originalValue,
  standalone,
  readOnly,
  disabled,
  hide,
  required,
  placeholder,
  error,
  variant,
  margin,
  fullWidth,
  size,
  options,
  label,
  helpLabel,
  onChange,
  onFocus,
  ...rest
}) => {
  // Register field name
  const {
    fieldName,
    defaultValue,
    registerField,
    error: formError,
    clearError,
  } = // eslint-disable-next-line react-hooks/rules-of-hooks
    name && !standalone ? useField(name) : ({} as ReturnType<typeof useField>);

  // Material UI styles
  const classes = useStyles();

  // Local states
  const [value, setValue] = useState<any>(
    originalValue || defaultValue || null,
  );

  // UUid to key
  const labelId = uuidv4();

  // Register field on unform
  useEffect(() => {
    if (name && !standalone)
      registerField({
        name: fieldName,
        getValue: () => value,
        setValue: (ref: any, newValue: any) => setValue(newValue),
        clearValue: () => setValue(null),
      });
  }, [registerField, name, standalone, fieldName, value]);

  // Set local value when external value changes
  useEffect(() => {
    setValue(originalValue);
  }, [originalValue]);

  // Handle input change
  const handleChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      setValue(event.target.value);
      if (typeof onChange === 'function') onChange(event.target.value);
    },
    [onChange],
  );

  // Handle input focus
  const handleFocus = useCallback(
    (event: React.FocusEvent) => {
      if (typeof clearError === 'function') clearError();
      if (typeof onFocus === 'function') onFocus(event);
    },
    [clearError, onFocus],
  );

  return (
    <Container hide={hide}>
      <FormControl
        className={classes.formControl}
        disabled={disabled}
        error={!!error}
        required={required}
        variant={variant}
        size={size}
        margin={margin}
      >
        {label && (
          <InputLabel
            shrink={value !== undefined && value !== null}
            id={labelId}
          >
            {label}
          </InputLabel>
        )}
        <MaterialUISelect
          {...rest}
          name={name || undefined}
          labelId={label ? labelId : undefined}
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          displayEmpty={!!placeholder}
          inputProps={{
            readOnly,
          }}
          input={
            variant === 'outlined' ? (
              <OutlinedInput
                notched={value !== undefined && value !== null}
                label={label}
              />
            ) : undefined
          }
          fullWidth={fullWidth}
          variant={variant || undefined}
        >
          {placeholder && (
            <MenuItem value="" disabled>
              {placeholder}
            </MenuItem>
          )}
          {options.map(option => (
            <MenuItem key={uuidv4()} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </MaterialUISelect>
        {(error || formError || helpLabel) && (
          <FormHelperText>{error || formError || helpLabel}</FormHelperText>
        )}
      </FormControl>
    </Container>
  );
};

Select.defaultProps = {
  name: null,
  value: undefined,
  standalone: false,
  readOnly: false,
  disabled: false,
  hide: false,
  required: false,
  placeholder: null,
  error: null,

  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
  size: 'small',

  label: null,
  helpLabel: null,

  onChange: undefined,
  onFocus: undefined,
};

export default Select;
