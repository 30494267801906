import { all, AllEffect, ForkEffect } from 'redux-saga/effects';

// Saga import
import auth from './auth/sagas';
import card from './card/sagas';
import statistics from './statistics/sagas';

export default function* rootSaga(): Generator<
  AllEffect<AllEffect<ForkEffect<never>>>,
  any,
  unknown
> {
  return yield all([auth, card, statistics]);
}
