import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import { CircularProgress } from '@material-ui/core';

// Component import
import Button from '../../../components/Form/Button';

// Service import
import api from '../../../services/api';

// Store import
import { refreshSession } from '../../../store/modules/auth/actions';
import { useCard } from '../../../hooks/useCard';

// Style import
import { Navigation } from '../styles';
import { Container } from './styles';
import { updateCardListRequest } from '../../../store/modules/card/actions';
import { updateStatisticsRequest } from '../../../store/modules/statistics/actions';

const CardActivation: React.FC = () => {
  // Card context
  const cardContext = useCard();

  // Get translation function
  const { t } = useTranslation();

  // Local states
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<string[]>([]);
  const [confirmPage, setConfirmPage] = useState(0);

  // Dispatch and history hook
  const dispatch = useDispatch();
  const history = useHistory();

  // Get files to load
  useEffect(() => {
    const selectedFiles = [
      'card_activation_step_1.md',
      'card_activation_step_2.md',
      'card_activation_step_3.md',
      'card_activation_step_4.md',
      'card_activation_contract.md',
    ];
    (async function loadFiles() {
      try {
        const promises = selectedFiles.map(async file => {
          const load = await fetch(`texts/${file}`);
          const text = await load.text();
          return text;
        });

        const results = await Promise.all(promises);

        setFiles(results);
      } catch (err) {
        setFiles([]);
        toast.error(
          t(
            '@general/CONNECTION_FAILURE_ERROR',
            'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
          ),
        );
      }
    })();
  }, [t]);

  // Submit function
  const handleSubmit = useCallback(async (): Promise<void> => {
    // Loading set
    setLoading(true);

    try {
      // API call
      await api.post('card/definitive', {
        send_mail: true,
        card_template: cardContext.selected_card_model + 1,
        card_color: cardContext.selected_card_color,
        phone_show: cardContext.phone_show,
        phone_order: cardContext.phone_order,
        whatsapp_show: cardContext.whatsapp_show,
        whatsapp_order: cardContext.whatsapp_order,
        email_show: cardContext.email_show,
        email_order: cardContext.email_order,
        website_show: cardContext.website_show,
        website_order: cardContext.website_order,
        address_show: cardContext.address_show,
        address_order: cardContext.address_order,
        facebook_show: cardContext.facebook_show,
        facebook_order: cardContext.facebook_order,
        linkedin_show: cardContext.linkedin_show,
        linkedin_order: cardContext.linkedin_order,
        twitter_show: cardContext.twitter_show,
        twitter_order: cardContext.twitter_order,
        instagram_show: cardContext.instagram_show,
        instagram_order: cardContext.instagram_order,
        youtube_show: cardContext.youtube_show,
        youtube_order: cardContext.youtube_order,
      });

      // Loading set
      setLoading(false);

      // Refresh the user sesion
      dispatch(refreshSession());
      dispatch(updateCardListRequest());
      dispatch(updateStatisticsRequest());

      // Send user to dashboard
      history.push('/dashboard');
    } catch (err) {
      // Check if server returned a error message and display to user
      if (err.response?.data?.message) toast.error(err.response.data.message);
      // Send communication error to user
      else
        toast.error(
          t(
            '@general/CONNECTION_FAILURE_ERROR',
            'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
          ),
        );

      // Loading set
      setLoading(false);
    }
  }, [
    t,
    cardContext.address_order,
    cardContext.address_show,
    cardContext.email_order,
    cardContext.email_show,
    cardContext.facebook_order,
    cardContext.facebook_show,
    cardContext.instagram_order,
    cardContext.instagram_show,
    cardContext.linkedin_order,
    cardContext.linkedin_show,
    cardContext.phone_order,
    cardContext.phone_show,
    cardContext.selected_card_color,
    cardContext.selected_card_model,
    cardContext.twitter_order,
    cardContext.twitter_show,
    cardContext.website_order,
    cardContext.website_show,
    cardContext.whatsapp_order,
    cardContext.whatsapp_show,
    cardContext.youtube_order,
    cardContext.youtube_show,
    dispatch,
    history,
  ]);

  return (
    <Container>
      <Navigation>
        <nav>
          <Button
            disabled={
              cardContext.selected_card_model === undefined ||
              cardContext.selected_card_color === undefined ||
              loading
            }
            onClick={() => cardContext.set_active_page(3)}
            colorType="tertiary"
            size="small"
          >
            {t('@card_activation_step/BACK', 'Voltar')}
          </Button>
          <p>
            {t(
              '@card_activation_step/READY',
              'Pronto para ativar o seu Cartão Digital?',
            )}
          </p>
          <div />
        </nav>
      </Navigation>
      <main>
        <div className="content">
          <div className="box">
            <h2>
              {t(
                '@card_activation_step/ACTIVATION_PROCESS_TITLE',
                'Processo de Ativação do Cartão Digital',
              )}
            </h2>
            {confirmPage === 0 && (
              <div className="page">
                <h3>
                  {t(
                    '@card_activation_step/STEP_ONE_TITLE',
                    'Passo 1/4 - Informações Importantes',
                  )}
                </h3>

                {files.length ? (
                  <div className="text">
                    <Markdown source={files[0]} />
                  </div>
                ) : (
                  <div className="loading">
                    <CircularProgress />
                  </div>
                )}

                <div className="button-area">
                  <Button
                    disabled={loading}
                    colorType="tertiary"
                    onClick={() => setConfirmPage(1)}
                  >
                    {t('@card_activation_step/NEXT', 'Prosseguir')}
                  </Button>
                </div>
              </div>
            )}
            {confirmPage === 1 && (
              <div className="page">
                <h3>
                  {t(
                    '@card_activation_step/STEP_2_TITLE',
                    'Passo 2/4 - Privacidade e Segurança - Aspectos Legais',
                  )}
                </h3>

                {files.length ? (
                  <div className="text">
                    <Markdown source={files[1]} />
                  </div>
                ) : (
                  <div className="loading">
                    <CircularProgress />
                  </div>
                )}

                <div className="button-area">
                  <Button
                    disabled={loading}
                    colorType="tertiary"
                    onClick={() => setConfirmPage(0)}
                  >
                    {t('@card_activation_step/BACK', 'Voltar')}
                  </Button>
                  <Button
                    disabled={loading}
                    colorType="tertiary"
                    onClick={() => setConfirmPage(2)}
                  >
                    {t('@card_activation_step/NEXT', 'Prosseguir')}
                  </Button>
                </div>
              </div>
            )}
            {confirmPage === 2 && (
              <div className="page">
                <h3>
                  {t(
                    '@card_activation_step/STEP_3_TITLE',
                    'Passo 3/4 - Aspectos Operacionais',
                  )}
                </h3>

                {files.length ? (
                  <div className="text">
                    <Markdown source={files[2]} />
                  </div>
                ) : (
                  <div className="loading">
                    <CircularProgress />
                  </div>
                )}

                <div className="button-area">
                  <Button
                    disabled={loading}
                    colorType="tertiary"
                    onClick={() => setConfirmPage(1)}
                  >
                    {t('@card_activation_step/BACK', 'Voltar')}
                  </Button>
                  <Button
                    disabled={loading}
                    colorType="tertiary"
                    onClick={() => setConfirmPage(3)}
                  >
                    {t('@card_activation_step/NEXT', 'Prosseguir')}
                  </Button>
                </div>
              </div>
            )}
            {confirmPage === 3 && (
              <div className="page">
                <h3>
                  {t(
                    '@card_activation_step/STEP_4_TITLE',
                    'Passo 4/4 - Termos de Uso',
                  )}
                </h3>

                {files.length ? (
                  <div className="text">
                    <Markdown source={files[3]} />
                  </div>
                ) : (
                  <div className="loading">
                    <CircularProgress />
                  </div>
                )}

                <div className="contractBox">
                  <div className="contractContent">
                    {files.length ? (
                      <div className="text">
                        <Markdown source={files[4]} />
                      </div>
                    ) : (
                      <div className="loading">
                        <CircularProgress />
                      </div>
                    )}
                  </div>
                </div>

                <div className="button-area">
                  <Button
                    disabled={loading}
                    colorType="tertiary"
                    onClick={() => setConfirmPage(2)}
                  >
                    {t('@card_activation_step/BACK', 'Voltar')}
                  </Button>
                  <Button
                    colorType="secondary"
                    loading={loading}
                    onClick={() => handleSubmit()}
                  >
                    {t('@card_activation_step/FINISH', 'Ativar Cartão')}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </Container>
  );
};

export default CardActivation;
