import styled from 'styled-components';

export const MenuButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;

  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    margin-top: 0.625rem;

    form {
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: stretch;
      flex-direction: column;

      .alert {
        margin-bottom: 0.9375rem;
      }

      input[type='file'] {
        display: none;
      }

      .file {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;

        > button {
          align-self: stretch;
        }

        .preview {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 1.25rem;
          margin-top: 1.25rem;

          border-radius: 0.25rem;

          img {
            background-color: ${({ theme }) => theme.background_secondary};
            border-radius: 0.25rem;
            width: 18.75rem;
            max-width: 70vw;
          }
        }
      }

      .box {
        section {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          align-self: stretch;
          gap: 1.25rem;

          .box-label {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 12.5rem;
          }

          .box-field {
            align-self: stretch;
            flex: 1;
            gap: 0.9375rem;

            .grid-1 {
              display: grid;
              grid-template-columns: 1fr;
            }

            .grid-2 {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: 0.9375rem;

              @media screen and (max-width: 1000px) {
                grid-template-columns: 1fr;
              }
            }

            .grid-3 {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              gap: 0.9375rem;

              @media screen and (max-width: 1000px) {
                grid-template-columns: 1fr;
              }
            }

            .grid-131 {
              display: grid;
              grid-template-columns: 1fr 3fr 1fr;
              gap: 0.9375rem;

              @media screen and (max-width: 1000px) {
                grid-template-columns: 1fr;
              }
            }

            .grid-231 {
              display: grid;
              grid-template-columns: 2fr 3fr 1fr;
              gap: 0.9375rem;

              @media screen and (max-width: 1000px) {
                grid-template-columns: 1fr;
              }
            }
          }

          @media screen and (max-width: 1000px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
`;

export const ModalFileContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;
`;

export const ModalGalleryContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;

  > div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: center;
    gap: 1.875rem;
    flex: 1;

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${({ theme }) => theme.background_secondary};
      border-radius: 0.25rem;

      img {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    > div {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (max-width: 500px) {
    > div {
      grid-template-columns: 1fr;
    }
  }
`;

export const ModalFileFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 !important;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    gap: 0.625rem;

    button {
      margin: 0 !important;
    }
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
    justify-content: center;

    > div {
      flex-direction: column;
    }
  }
`;
