import { produce } from 'immer';
import { Reducer } from 'redux';

// Type import
import { AuthActionTypes } from '../auth/types';
import { CardActionTypes, ICardState } from './types';

const INITIAL_STATE: ICardState = {
  list: [],
  updated: new Date(),
  loading: true,
  refreshing: true,
};

const card: Reducer<ICardState> = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case CardActionTypes.UPDATE_CARD_LIST_FAILURE:
      case AuthActionTypes.SIGN_FAILURE:
      case AuthActionTypes.SIGN_OUT:
        draft.updated = new Date();
        draft.list = [];
        draft.loading = false;
        draft.refreshing = false;
        break;

      case CardActionTypes.UPDATE_CARD_LIST_REQUEST:
        draft.updated = new Date();
        draft.refreshing = true;
        break;

      case CardActionTypes.UPDATE_CARD_LIST_SUCCESS:
        draft.updated = new Date();
        draft.list = action.payload.cards;
        draft.loading = false;
        draft.refreshing = false;
        break;

      default:
    }
  });
};

export default card;
