import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';

// Service import
import api from '../../../services/api';

// Action import
import {
  updateStatisticsSuccess,
  updateStatisticsFailure,
  updateStatisticsRequest,
} from './actions';

// Type import
import { AuthActionTypes, ISignInSuccessAction } from '../auth/types';
import { StatisticsActionTypes } from './types';

// Get translation function
import { t } from '../../../i18n';

export function* updateStatisticsEffect() {
  try {
    // API call
    const response: AxiosResponse = yield call(api.get, 'statistics');

    // Handle data
    yield put(updateStatisticsSuccess(response.data));
  } catch (err) {
    // Check if server had return a message
    if (err.response?.data?.message) toast.error(err.response.data.message);
    else
      toast.error(
        t(
          '@general/CONNECTION_FAILURE_ERROR',
          'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
        ),
      );
    yield put(updateStatisticsFailure());
  }
}

export function* signInSuccessStatisticsEffect({
  payload,
}: ISignInSuccessAction) {
  // Load statistics on login
  if (payload.user.card_created_at) yield put(updateStatisticsRequest());
}

export default all([
  takeLatest(
    StatisticsActionTypes.UPDATE_STATISTICS_REQUEST,
    updateStatisticsEffect,
  ),
  takeLatest(AuthActionTypes.SIGN_IN_SUCCESS, signInSuccessStatisticsEffect),
]);
