const CardModels = {
  categories: [
    {
      value: 0,
      label: 'Clássica',
    },
    {
      value: 1,
      label: 'Geométrica (sem imagem de fundo)',
    },
    {
      value: 2,
      label: 'Geométrica (com imagem de fundo)',
    },
  ],

  models: [
    {
      id: 1,
      imageUrl: '/cards/mod_1_var_1.png',
      name: 'Modelo 1',
      category: 0,
      colors: [
        {
          id: 1,
          image: '/cards/mod_1_var_1.png',
          color: ['#5b1842'],
        },
        {
          id: 2,
          image: '/cards/mod_1_var_2.png',
          color: ['#3c2115'],
        },
        {
          id: 3,
          image: '/cards/mod_1_var_3.png',
          color: ['#153c59'],
        },
        {
          id: 4,
          image: '/cards/mod_1_var_4.png',
          color: ['#000000'],
        },
      ],
    },
    {
      id: 2,
      imageUrl: '/cards/mod_2_var_1.svg',
      name: 'Modelo 1',
      category: 1,
      colors: [
        {
          id: 1,
          image: '/cards/mod_2_var_1.svg',
          color: ['#754e68'],
        },
        {
          id: 2,
          image: '/cards/mod_2_var_2.svg',
          color: ['#676675'],
        },
        {
          id: 3,
          image: '/cards/mod_2_var_3.svg',
          color: ['#877455'],
        },
      ],
    },
    {
      id: 3,
      imageUrl: '/cards/mod_3_var_1.svg',
      name: 'Modelo 2',
      category: 1,
      colors: [
        {
          id: 1,
          image: '/cards/mod_3_var_1.svg',
          color: ['#775812'],
        },
        {
          id: 2,
          image: '/cards/mod_3_var_2.svg',
          color: ['#494949'],
        },
        {
          id: 3,
          image: '/cards/mod_3_var_3.svg',
          color: ['#754e68'],
        },
      ],
    },
    {
      id: 4,
      imageUrl: '/cards/mod_4_var_1.svg',
      name: 'Modelo 3',
      category: 1,
      colors: [
        {
          id: 1,
          image: '/cards/mod_4_var_1.svg',
          color: ['#3e6370'],
        },
        {
          id: 2,
          image: '/cards/mod_4_var_2.svg',
          color: ['#66433a'],
        },
        {
          id: 3,
          image: '/cards/mod_4_var_3.svg',
          color: ['#440d2e'],
        },
      ],
    },
    {
      id: 5,
      imageUrl: '/cards/mod_5_var_1.svg',
      name: 'Modelo 1',
      category: 2,
      colors: [
        {
          id: 1,
          image: '/cards/mod_5_var_1.svg',
          color: ['#141519'],
        },
        {
          id: 2,
          image: '/cards/mod_5_var_2.svg',
          color: ['#131a33'],
        },
      ],
    },
    {
      id: 6,
      imageUrl: '/cards/mod_6_var_1.svg',
      name: 'Modelo 2',
      category: 2,
      colors: [
        {
          id: 4,
          image: '/cards/mod_6_var_1.svg',
          color: ['#2D0404'],
        },
        {
          id: 5,
          image: '/cards/mod_6_var_2.svg',
          color: ['#494949'],
        },
      ],
    },
    {
      id: 7,
      imageUrl: '/cards/mod_7_var_1.svg',
      name: 'Modelo 3',
      category: 2,
      colors: [
        {
          id: 1,
          image: '/cards/mod_7_var_1.svg',
          color: ['#2D0404'],
        },
        {
          id: 2,
          image: '/cards/mod_7_var_2.svg',
          color: ['#160907'],
        },
      ],
    },
  ],
};

export default CardModels;
