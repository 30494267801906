import { AnyAction } from 'redux';

// Type import
import { AuthActionTypes, ISessionData, ISignInRequestAction } from './types';

export function signInRequest(
  cpf: string,
  password: string,
): ISignInRequestAction {
  return {
    type: AuthActionTypes.SIGN_IN_REQUEST,
    payload: {
      cpf,
      password,
    },
  };
}

export function signInSuccess(data: ISessionData): AnyAction {
  return {
    type: AuthActionTypes.SIGN_IN_SUCCESS,
    payload: data,
  };
}

export function signOut(): AnyAction {
  return {
    type: AuthActionTypes.SIGN_OUT,
    payload: {},
  };
}

export function sessionResumeRequest(): AnyAction {
  return {
    type: AuthActionTypes.SESSION_RESUME_REQUEST,
    payload: {},
  };
}

export function refreshSession(): AnyAction {
  return {
    type: AuthActionTypes.REFRESH_SESSION,
    payload: {},
  };
}

export function sessionRefreshSuccess(data: ISessionData): AnyAction {
  return {
    type: AuthActionTypes.SESSION_REFRESH_SUCCESS,
    payload: data,
  };
}

export function signFailure(): AnyAction {
  return {
    type: AuthActionTypes.SIGN_FAILURE,
    payload: {},
  };
}
