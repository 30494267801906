import React, { useCallback, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputMask from 'react-input-mask';
import { useField } from '@unform/core';

// Style import
import { Container } from './styles';

// Interfaces
interface ITextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
  mask: string;
}

interface IInputProps {
  name?: string | null;
  value?: any;
  standalone?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  hide?: boolean;
  required?: boolean;
  placeholder?: string | null;
  error?: string | null;

  type?: 'text' | 'password' | 'number';
  variant?: null | 'filled' | 'outlined';
  multiline?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  size?: 'small' | 'medium';
  fullWidth?: boolean;
  margin?: 'normal' | 'dense' | 'none';
  mask?: string;

  label?: string | null;
  helpLabel?: string | null;

  onChange?: (value: any) => any;
  onFocus?: (event: React.FocusEvent) => any;

  [key: string]: any;
}

// Input mask
const TextMaskCustom: React.FC<ITextMaskCustomProps> = ({
  inputRef,
  mask,
  ...rest
}) => {
  return (
    <InputMask
      {...rest}
      mask={mask}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
    />
  );
};

const Input: React.FC<IInputProps> = ({
  name,
  value: originalValue,
  standalone,
  readOnly,
  disabled,
  hide,
  required,
  placeholder,
  error,

  type,
  variant,
  multiline,
  startAdornment,
  endAdornment,
  size,
  fullWidth,
  margin,
  mask,

  label,
  helpLabel,

  onChange,
  onFocus,

  ...rest
}) => {
  // Register field name
  const {
    fieldName,
    defaultValue,
    registerField,
    error: formError,
    clearError,
  } = // eslint-disable-next-line react-hooks/rules-of-hooks
    name && !standalone ? useField(name) : ({} as ReturnType<typeof useField>);

  // Local states
  const [value, setValue] = useState<any>(
    originalValue || defaultValue || null,
  );

  // Register field on unform
  useEffect(() => {
    if (name && !standalone)
      registerField({
        name: fieldName,
        getValue: () => value,
        setValue: (ref: any, newValue: any) => setValue(newValue),
        clearValue: () => setValue(null),
      });
  }, [registerField, name, standalone, fieldName, value]);

  // Set local value when external value changes
  useEffect(() => {
    setValue(originalValue);
  }, [originalValue]);

  // Handle input change
  const handleChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      setValue(event.target.value);
      if (typeof onChange === 'function') onChange(event.target.value);
    },
    [onChange],
  );

  // Handle input focus
  const handleFocus = useCallback(
    (event: React.FocusEvent) => {
      if (typeof clearError === 'function') clearError();
      if (typeof onFocus === 'function') onFocus(event);
    },
    [clearError, onFocus],
  );

  return (
    <Container hide={hide}>
      <TextField
        {...rest}
        name={name || undefined}
        value={value || ''}
        InputProps={{
          readOnly,
          startAdornment: startAdornment ? (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ) : undefined,
          endAdornment: endAdornment ? (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ) : undefined,
          ...(!!mask && {
            inputComponent: TextMaskCustom as any,
            inputProps: {
              mask,
            },
          }),
        }}
        InputLabelProps={{
          shrink: value !== undefined,
        }}
        size={size}
        fullWidth={fullWidth}
        margin={margin}
        disabled={disabled}
        required={required}
        placeholder={placeholder || undefined}
        error={!!error || !!formError}
        label={label}
        helperText={error || formError || helpLabel}
        type={type}
        variant={variant || undefined}
        multiline={multiline}
        defaultValue={defaultValue}
        onChange={handleChange}
        onFocus={handleFocus}
      />
    </Container>
  );
};

Input.defaultProps = {
  name: null,
  value: undefined,
  standalone: false,
  readOnly: false,
  disabled: false,
  hide: false,
  required: false,
  placeholder: null,
  error: null,

  type: 'text',
  variant: 'outlined',
  multiline: false,
  startAdornment: undefined,
  endAdornment: undefined,
  size: 'small',
  fullWidth: true,
  margin: 'normal',
  mask: undefined,

  label: null,
  helpLabel: null,

  onChange: undefined,
  onFocus: undefined,
};

export default Input;
