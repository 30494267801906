import React, { useEffect, useState } from 'react';
import { Checkbox, CircularProgress } from '@material-ui/core';
import Markdown from 'react-markdown';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

// Component import
import Button from '../../../components/Form/Button';

// Style import
import { Container, ModelPreview } from './styles';
import { Navigation } from '../styles';

// Hook import
import { useCard } from '../../../hooks/useCard';

// Card models import
import card_models from '../../../hooks/useCard/models';

const CardValidation: React.FC = () => {
  // Context
  const cardContext = useCard();
  const { selected_card_model } = cardContext;

  // Local states
  const [agree, setAgree] = useState(false);
  const [file, setFile] = useState('');

  // Get translation function
  const { t } = useTranslation();

  // Get files to load
  useEffect(() => {
    (async function loadFiles() {
      try {
        const load = await fetch(`texts/card_validation.md`);
        const text = await load.text();

        setFile(text);
      } catch (err) {
        setFile('');
        toast.error(
          t(
            '@general/CONNECTION_FAILURE_ERROR',
            'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
          ),
        );
      }
    })();
  }, [t]);

  return (
    <Container>
      <Navigation>
        <nav>
          <Button
            disabled={
              cardContext.selected_card_model === undefined ||
              cardContext.selected_card_color === undefined
            }
            onClick={() => cardContext.set_active_page(2)}
            colorType="tertiary"
            size="small"
          >
            {t('@card_validation_step/RETURN', 'Voltar')}
          </Button>
          <p>
            {t(
              '@card_validation_step/HEADER_MESSAGE',
              'Estamos quase lá! Aqui você poderá verificar o correto funcionamento do seu Cartão Digital.',
            )}
          </p>
          <Button
            colorType="tertiary"
            size="small"
            onClick={() => cardContext.set_active_page(4)}
            disabled={!agree}
          >
            {t('@card_validation_step/NEXT', 'Avançar')}
          </Button>
        </nav>
      </Navigation>
      <main>
        <div className="content">
          <div className="box">
            <ModelPreview>
              {!!cardContext.card_preview && (
                <>
                  <img
                    src={String(cardContext.card_preview)}
                    alt={card_models.models[selected_card_model].name}
                  />
                  <div>
                    {t(
                      '@card_validation_step/ILLUSTRATION',
                      'Imagem Ilustrativa',
                    )}
                  </div>
                </>
              )}
            </ModelPreview>
          </div>

          <div className="box description">
            {file ? (
              <div className="text">
                <Markdown source={file} />
              </div>
            ) : (
              <div className="loading">
                <CircularProgress />
              </div>
            )}

            <div className="checkArea">
              <Checkbox
                checked={agree}
                onChange={(_, checked) => setAgree(checked)}
              />
              <strong>
                {t(
                  '@card_validation_step/AGREE_TERMS',
                  'Concordo com a composição e configuração do Cartão Digital (modelo, imagem e links) e todos os links foram revistos e testados.',
                )}
              </strong>
            </div>
          </div>
        </div>
      </main>
    </Container>
  );
};

export default CardValidation;
