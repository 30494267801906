interface IRequest {
  street?: string;
  number?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
  zipcode?: string;
}

export default function compileGoogleMapsAddressUrl({
  street,
  number,
  neighborhood,
  city,
  state,
  zipcode,
}: IRequest): string {
  let address = '';
  if (street) address += `${street} `;
  if (number) address += `${number} `;
  if (neighborhood) address += `${neighborhood} `;
  if (city) address += `${city} `;
  if (state) address += `${state} `;
  if (zipcode) address += `${zipcode} `;
  const url = `https://www.google.com/maps/search/?api=1&query=${encodeURI(
    address,
  )}`;
  return url;
}
