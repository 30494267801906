export function GetUserStr(str: string) {
  const res = str
    .split(' ')
    .filter(word => {
      if (word === '') return null;
      return true;
    })
    .map(word => word.toUpperCase());

  let sort = [];
  let bigger = '';
  const final: string[] = [];
  switch (res.length) {
    case 0:
      return null;

    case 1:
      return [res[0].charAt(0)];

    case 2:
      return [res[0].charAt(0), res[1].charAt(0)];

    default:
      sort = res.filter(word => {
        if (word.length <= 2) return false;
        return true;
      });
      final[0] = sort[0].charAt(0);

      for (let i = 1; i < sort.length; i += 1)
        if (sort[i].length > bigger.length) bigger = sort[i];
      final[1] = bigger.charAt(0);
  }
  return final;
}
